import { useCallback, useEffect, useState } from 'react'

import callbackOnChange from '../../lib/hooks/callback-on-change'

type props = {
  defaultStartDate: Date | undefined
  defaultEndDate: Date | undefined
}

export default function useDateRange({
  defaultStartDate,
  defaultEndDate,
}: props) {
  const [dateRange, setDateRange] = useState<
    [Date | undefined, Date | undefined]
  >([undefined, undefined])

  callbackOnChange(
    [defaultStartDate, defaultEndDate],
    useCallback(() => {
      setDateRange([defaultStartDate, defaultEndDate])
    }, [setDateRange, defaultStartDate, defaultEndDate])
  )

  useEffect(() => {
    const [startDate] = dateRange
    if (startDate === undefined && defaultStartDate !== undefined) {
      setDateRange([defaultStartDate, defaultEndDate])
    }
  }, [dateRange, setDateRange, defaultStartDate, defaultEndDate])

  const [startDate, endDate] = dateRange

  return [{ startDate, endDate }, setDateRange] as const
}
