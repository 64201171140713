import { pick } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useAddRpmAccountCustomerAccountMappingMutation } from './request/add-rpm-account-customer-account-mapping'
import { useDeleteRpmAccountCustomerAccountMappingMutation } from './request/delete-rpm-account-customer-account-mapping'
import { useGetRpmAccountCustomerAccountMappingsQuery } from './request/rpm-account-customer-account-mappings'
import { useGetRpmAccountsQuery } from './request/rpm-accounts'
import { State } from '../types'

const claimsTeamIds = [33, 42, 49, 57, 64, 67, 68, 69, 77, 75, 82, 83]

export default function usePersons({
  customerAccountId,
}: { customerAccountId?: number } = {}) {
  const isAuthorized = useSelector((state: State) => state.user.isAuthorized)

  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetRpmAccountsQuery(undefined, { skip: !isAuthorized })

  const { data: rpmAccountCustomerAccountMappings = [] } =
    useGetRpmAccountCustomerAccountMappingsQuery(undefined, {
      skip: !isAuthorized,
    })

  const [
    fetchAddRpmAccountCustomerAccountMappings,
    // addRpmAccountCustomerAccountMappingsRequest,
  ] = useAddRpmAccountCustomerAccountMappingMutation()

  const [
    fetchDeleteRpmAccountCustomerAccountMappings,
    // deleteRpmAccountCustomerAccountMappingsRequest,
  ] = useDeleteRpmAccountCustomerAccountMappingMutation()

  const persons = useMemo(() => {
    return data
      .filter((it) => claimsTeamIds.includes(it.accountId))
      .map((it) => pick(it, ['userName', 'accountId']))
  }, [data])

  const personsForCustomer = useMemo(() => {
    return persons.filter((person) => {
      const mapping = (rpmAccountCustomerAccountMappings || []).find(
        (it) =>
          it.customerAccountId === customerAccountId &&
          it.rpmAccountId === person.accountId
      )

      return mapping !== undefined
    })
  }, [persons, customerAccountId, rpmAccountCustomerAccountMappings])

  const addPerson = useCallback(
    (person: { accountId: number }) => {
      const personExists =
        personsForCustomer.find((pfc) => pfc.accountId === person.accountId) !==
        undefined
      if (customerAccountId && !personExists) {
        void fetchAddRpmAccountCustomerAccountMappings({
          rpmAccountId: person.accountId,
          customerAccountId,
        })
      }
    },
    [
      customerAccountId,
      personsForCustomer,
      fetchAddRpmAccountCustomerAccountMappings,
    ]
  )

  const removePerson = useCallback(
    (person: { accountId: number }) => {
      if (customerAccountId) {
        void fetchDeleteRpmAccountCustomerAccountMappings({
          rpmAccountId: person.accountId,
          customerAccountId,
        })
      }
    },
    [customerAccountId, fetchDeleteRpmAccountCustomerAccountMappings]
  )

  return {
    persons,
    personsForCustomer,
    addPerson,
    removePerson,
    isLoading,
    isFetching,
  }
}
