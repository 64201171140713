import { groupBy } from 'lodash'
import { useMemo } from 'react'

import { RDSCClaim } from '../../types'
import { tabStatusesLookup } from '../config/tab-statuses'

export const useClaimsForTab = (
  data: RDSCClaim[],
  tab: 'new' | 'requested' | 'submitted' | 'resolved'
) => {
  const [refundStatuses, reconcileStatuses] = tabStatusesLookup[tab]

  const filtered = useMemo(() => {
    const filtered: RDSCClaim[] = []

    for (const shipmentClaims of Object.values(groupBy(data, 'shipmentId'))) {
      const shipmentContainsRequested =
        shipmentClaims.find((it) => {
          const isRequestedStatus =
            tabStatusesLookup['requested'][1].includes(it.reconcileStatusId) &&
            tabStatusesLookup['requested'][0].includes(it.amzRefundStatusId)

          return isRequestedStatus
        }) !== undefined

      for (const claim of shipmentClaims) {
        const isRefundStatusOfTab = refundStatuses.includes(
          claim.amzRefundStatusId
        )

        const isReconcileStatusOfTab = reconcileStatuses.includes(
          claim.reconcileStatusId
        )

        const isResolvedStatus =
          tabStatusesLookup['resolved'][1].includes(claim.reconcileStatusId) ||
          tabStatusesLookup['resolved'][0].includes(claim.amzRefundStatusId)

        if (tab === 'resolved') {
          if (isRefundStatusOfTab || isReconcileStatusOfTab) {
            filtered.push(claim)
            continue
          }
        }

        if (tab === 'submitted') {
          if (isRefundStatusOfTab && !isResolvedStatus) {
            filtered.push(claim)
            continue
          }
        }

        if (tab === 'requested') {
          if (shipmentContainsRequested && !isResolvedStatus) {
            filtered.push(claim)
            continue
          }
        }

        if (tab === 'new') {
          if (shipmentContainsRequested) {
            continue
          }
        }

        if (isRefundStatusOfTab && isReconcileStatusOfTab) {
          filtered.push(claim)
        }
      }
    }

    return filtered
  }, [data])

  return filtered
}
