import { useCallback, useState } from 'react'

export default (): [(value: string) => void, boolean] => {
  const [isCopied, setCopied] = useState<boolean>(false)

  const copy = useCallback(
    (value: string) => {
      setCopied(true)

      const textarea = document.createElement('textarea')
      textarea.value = value
      textarea.style.position = 'fixed'
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()

      document.execCommand('copy')
      document.body.removeChild(textarea)

      setTimeout(() => setCopied(false), 1000)
    },
    [setCopied]
  )

  return [copy, isCopied]
}
