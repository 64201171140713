import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const rdscApi = createApi({
  reducerPath: 'rdscApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
  tagTypes: [
    'Claims',
    'Stats',
    'Accounts',
    'AccountMappings',
    'DisabledCustomerAccounts',
  ],
  endpoints: () => ({}),
})

export default rdscApi
