import { isEmpty } from 'lodash'
import React from 'react'
import { Check, X } from 'react-feather'
import { SpinnerCircularSplit } from 'spinners-react'

import { RDSCClaim } from './../../types'
import { useApplyChanges } from '../hooks/component/skus-inputs'

export default function SKUsNewInputs({
  selectedShipmentsData,
  unselectRows,
  selectedRowsData,
  selectedCustomerId,
}: {
  selectedShipmentsData: RDSCClaim[]
  unselectRows: () => void
  selectedRowsData: RDSCClaim[]
  selectedCustomerId: number
}) {
  const {
    selectAction,
    selectedAction,
    applyChanges,
    request,
    isDisplayingError,
    isDisplayingSuccess,
    details,
  } = useApplyChanges({
    selectedShipmentsData,
    unselectRows,
    selectedRowsData,
    selectedCustomerId,
  })

  const { isLoading, isError } = request

  return (
    <>
      <label htmlFor="actionType" className="form-label">
        Set SKU Status:
      </label>

      <select
        id="actionType"
        aria-label="Default select example"
        disabled={selectedRowsData.length === 0}
        className={`form-select ${isError ? 'is-invalid' : ''}`}
        value={selectedAction}
        onChange={(e) => {
          selectAction(e.target.value)
        }}
      >
        <option value={0}>Select Status</option>
        <option value={'reconcile-6'}>Reconciled</option>
        <option value={'reconcile-7'}>Invalid</option>
        <option value={'reconcile-8'}>Dismissed</option>
      </select>

      <div className="invalid-feedback">Failed to update status</div>

      <div className="mt-4">
        <div className="float-end d-flex flex-row align-items-center">
          {isDisplayingSuccess ? (
            <Check className="text-success me-2" size={30} />
          ) : null}

          {isDisplayingError ? (
            <X className="text-danger me-2" size={30} />
          ) : null}

          {isLoading ? (
            <SpinnerCircularSplit className="me-2" size={30} />
          ) : null}

          <button
            disabled={isEmpty(details)}
            className="btn btn-md btn-success"
            onClick={() => {
              void applyChanges()
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  )
}
