import rdscApi from './../../reducers/request/rdsc'
import getClaimsQuery from './rdsc-claims'
import { RefundDetails } from '../../../types/query'

type HttpStatusCode = 200 | 201 | 204 | 400 | 401 | 403 | 404 | 500

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    updateRefundsDetails: build.mutation<
      HttpStatusCode,
      {
        amzRefundIds: number[]
        customerId: number
        details: RefundDetails
      }
    >({
      query: ({ amzRefundIds, details, customerId }) => ({
        url: 'amz-refunds-details',
        method: 'POST',
        body: { amzRefundIds, details, customerId },
      }),
      async onQueryStarted(
        { amzRefundIds, customerId, details },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled

          dispatch(
            getClaimsQuery.util.updateQueryData(
              'getClaims',
              customerId,
              (draft) => {
                for (const item of draft) {
                  if (amzRefundIds.includes(item.amzRefundId)) {
                    if (details.refundStatusId) {
                      item.amzRefundStatusId = details.refundStatusId
                    }
                    if (details.reconcileStatusId) {
                      item.reconcileStatusId = details.reconcileStatusId
                    }
                    if (details.caseId !== undefined) {
                      item.amzCaseId = details.caseId
                    }
                    if (details.notes !== undefined) {
                      item.caseManagerNotes = details.notes
                    }
                  }
                }
              }
            )
          )
        } catch (e) {
          console.log(e, 'mutate customer claims')
        }
      },
    }),
  }),
})

export const { useUpdateRefundsDetailsMutation } = extension
export default extension
