import { isEmpty, uniqBy } from 'lodash'
import React, { memo } from 'react'
import { Search, X } from 'react-feather'

import { PRODUCT_STATUSES, REFUND_STATUSES } from './../constants'
import ClaimsLayout from './claims-layout'
import ClaimsResolvedInputs from './claims-resolved-inputs'
import DatePicker from './date-picker'
import { RDSCClaim } from '../../types'
import useClaimsTable from '../hooks/component/claims-table'

export type RDSCClaimWithStatus = RDSCClaim & {
  status: string | undefined
}

export default memo(function ResolvedClaims({
  isShowing,
  selectedCustomerId,
  selectShipmentId,
}: {
  isShowing: boolean
  selectedCustomerId: number
  selectShipmentId: (id: string) => void
}) {
  const {
    isModalShowing,
    setIsModalShowing,

    isLoading,
    isFetching,

    selectedShipmentIds,
    selectedShipmentsData,
    nClaimsByShipmentId,

    nReimbursableClaims,
    reimbursableValue,

    storeNames,
    selectedStoreNames,
    removeStoreName,
    addStoreName,

    table,
    dateRange,
    setDateRange,

    setSearchQuery,

    refundStatusIds,
    selectedRefundStatusIds,
    addRefundStatusId,
    removeRefundStatusId,

    reconcileStatusIds,
    selectedReconcileStatusIds,
    addReconcileStatusId,
    removeReconcileStatusId,
  } = useClaimsTable({
    selectedCustomerId,
    selectShipmentId,
    tabId: 'resolved',
  })

  const existingNotes = uniqBy(
    selectedShipmentsData.filter((it) => !isEmpty(it.caseManagerNotes)),
    'shipmentId'
  )

  return (
    <ClaimsLayout
      {...{
        isShowing: isShowing && !isLoading && !isFetching,
        isModalShowing,
        setIsModalShowing,
        selectedShipmentIds,
        selectedShipmentsData,
        nClaims: nClaimsByShipmentId,
        statsComponent: (
          <div className="d-flex flex-row flex-wrap">
            <div className="me-4">
              <span>Quantity:</span>
              <span className="text-success fw-bold ms-2">
                {nReimbursableClaims}
              </span>
            </div>
            <div>
              <span>Value:</span>
              <span className="text-success fw-bold ms-2">
                {reimbursableValue}
              </span>
            </div>
          </div>
        ),
        filtersComponent: (
          <div className="float-end">
            <div className="d-flex flex-row">
              {selectedReconcileStatusIds.map((statusId: number, i) => {
                return (
                  <div
                    className="me-3 btn btn-sm btn-secondary"
                    key={i}
                    onClick={() => {
                      removeReconcileStatusId(statusId)
                    }}
                  >
                    {PRODUCT_STATUSES[statusId]} <X {...{ size: 15 }} />
                  </div>
                )
              })}
              {selectedRefundStatusIds.map((statusId: number, i) => {
                return (
                  <div
                    className="me-3 btn btn-sm btn-secondary"
                    key={i}
                    onClick={() => {
                      removeRefundStatusId(statusId)
                    }}
                  >
                    {REFUND_STATUSES[statusId]} <X {...{ size: 15 }} />
                  </div>
                )
              })}
              {selectedStoreNames.map((storeName: string) => {
                return (
                  <div
                    className="me-3 btn btn-sm btn-secondary"
                    key={storeName}
                    onClick={() => {
                      removeStoreName(storeName)
                    }}
                  >
                    {storeName} <X {...{ size: 15 }} />
                  </div>
                )
              })}

              <div className="form-group">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control"
                    type="text"
                    defaultValue=""
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
              </div>

              <div className="btn-group ms-2" role="group">
                <div className="btn-group btn-group-sm">
                  <DatePicker
                    {...{
                      minDate: dateRange.min,
                      maxDate: dateRange.max,
                      onChange: setDateRange,
                    }}
                  />
                  <button
                    className="btn btn-light btn-outline-dark border-custom dropdown-toggle px-3"
                    type="button"
                    id="actionType"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Status
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="statusType">
                    {refundStatusIds.map((statusId: number, i: number) => {
                      return (
                        <li key={i} onClick={() => addRefundStatusId(statusId)}>
                          <a className="dropdown-item" href="#">
                            {REFUND_STATUSES[statusId]}
                          </a>
                        </li>
                      )
                    })}
                    {reconcileStatusIds.map((statusId: number, i: number) => {
                      return (
                        <li
                          key={i}
                          onClick={() => addReconcileStatusId(statusId)}
                        >
                          <a className="dropdown-item" href="#">
                            {PRODUCT_STATUSES[statusId]}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>

                <div className="btn-group btn-group-sm">
                  <button
                    className="btn btn-light btn-outline-dark border-custom dropdown-toggle px-3"
                    type="button"
                    id="actionType"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Store
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="statusType">
                    {storeNames.map((storeName: string | undefined) => {
                      return (
                        <li
                          key={storeName}
                          onClick={() => addStoreName(storeName || '')}
                        >
                          <a className="dropdown-item" href="#">
                            {storeName}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ),
        tableComponent: table,
        inputsComponent: existingNotes.length ? (
          <ClaimsResolvedInputs
            {...{
              selectedShipmentsData,
              selectedShipmentIds,
              selectedCustomerId,
              // unselectRows,
            }}
          />
        ) : undefined,
      }}
    />
  )
})
