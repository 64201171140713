import React, { KeyboardEvent, useEffect, useState } from 'react'
import { Eye, EyeOff, Lock } from 'react-feather'
import { FieldValues, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { SpinnerCircularSplit, SpinnerDiamond } from 'spinners-react'

import { useAuthorizeMutation } from './../hooks/request/authorize'
import { useAuthenticateQuery } from '../hooks/request/authenticate'
import CenterContainer from '../lib/components/center-container'
import Modal from '../lib/components/modal'
import { authorize } from '../reducers/user'
import { State } from '../types'

export default function ModalAuthorize() {
  const dispatch = useDispatch()
  const isAuthorized = useSelector((state: State) => state.user.isAuthorized)

  const [isShowingSuccess, setIsShowingSuccess] = useState(false)
  const [isShowingPassword, setIsShowingPassword] = useState(false)

  const [lastMutationRequestId, setLastMutationRequestId] = useState('')

  const {
    isLoading,
    isFetching: isAuthenticating,
    isSuccess,
    data,
    requestId,
  } = useAuthenticateQuery()

  const [
    fetchAuthorize,
    {
      isError,
      isSuccess: isMutationSuccess,
      isLoading: isMutationLoading,
      error,
      requestId: mutationRequestId,
    },
  ] = useAuthorizeMutation()

  const onSubmit = (data: FieldValues) => {
    void fetchAuthorize(data as { username: string; password: string })
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      void handleSubmit(onSubmit)()
    }
  }

  const [lastRequestId, setLastRequestId] = useState('')

  useEffect(() => {
    if (
      isSuccess &&
      !isLoading &&
      data === 'OK' &&
      !isAuthorized &&
      requestId &&
      lastRequestId !== requestId
    ) {
      setLastRequestId(requestId)
      dispatch(authorize(true))
    }
  }, [
    isSuccess,
    isLoading,
    data,
    isAuthorized,
    dispatch,
    authorize,
    requestId,
    lastRequestId,
    setLastRequestId,
  ])

  useEffect(() => {
    if (
      isMutationSuccess &&
      !isAuthorized &&
      mutationRequestId !== undefined &&
      lastMutationRequestId !== mutationRequestId
    ) {
      setIsShowingSuccess(true)
      dispatch(authorize(true))
      setLastMutationRequestId(mutationRequestId)
      setTimeout(() => {
        setIsShowingSuccess(false)
      }, 1000)
    }
  }, [
    isMutationSuccess,
    mutationRequestId,
    lastMutationRequestId,
    setLastMutationRequestId,
    setIsShowingSuccess,
    dispatch,
    authorize,
    isAuthorized,
  ])

  const { register, handleSubmit } = useForm()

  return (!isAuthorized || isShowingSuccess) && !isAuthenticating ? (
    <Modal
      {...{
        title: (
          <span>
            {!isMutationLoading ? (
              <Lock className="me-2 mb-1" />
            ) : (
              <SpinnerCircularSplit size="25" className="me-2 mb-1" />
            )}
            Sign In
          </span>
        ),
        isFloating: true,
        style: { zIndex: 2000 },
        onSubmit: () => {
          void handleSubmit(onSubmit)()
        },
      }}
    >
      {isShowingSuccess ? (
        <CenterContainer style={{ minHeight: '150px' }}>
          <SpinnerDiamond />
        </CenterContainer>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column">
            {isError && error && error.status !== 401 ? (
              <span className="text-danger">
                Something went wrong, please try reloading
              </span>
            ) : null}
            <label htmlFor="newShipmentId" className="form-label">
              Username:
            </label>
            <div className="input-group mb-2">
              <input
                id="username"
                className={`form-control ${
                  isError && error && error.status === 401 ? 'is-invalid' : ''
                }`}
                {...register('username', { required: true })}
                onKeyDown={handleKeyDown}
              />
            </div>
            <label htmlFor="password" className="form-label">
              Password:
            </label>
            <div className="input-group">
              <input
                id="password"
                className={`form-control ${
                  isError && error && error.status === 401 ? 'is-invalid' : ''
                }`}
                {...register('password', { required: true })}
                type={isShowingPassword ? 'text' : 'password'}
                onKeyDown={handleKeyDown}
              />
              <button
                className="input-group-text btn-gray"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Show Password"
                type="button"
                onClick={() => setIsShowingPassword(!isShowingPassword)}
              >
                {!isShowingPassword ? <Eye /> : <EyeOff />}
              </button>
              <div className="invalid-feedback text-end">
                Username or password is incorrect.
              </div>
            </div>
          </div>
        </form>
      )}
    </Modal>
  ) : null
}
