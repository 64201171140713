import { uniq } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { RDSCStats } from '../../types'

export const useSelectPersonsClaims = (
  claims: (RDSCStats & { persons: string })[]
) => {
  const {
    selectedPersons,

    addPerson,
    removePerson,
    resetPersons,

    persons,
  } = usePersons(claims)

  const selectedPersonsClaims = useSelectedPersonClaims({
    selectedPersons,

    claims,
  })

  return {
    claims: selectedPersonsClaims,

    selectedPersons,

    addPerson,
    removePerson,
    resetPersons,

    persons,
  }
}

const useSelectedPersonClaims = ({
  selectedPersons,

  claims,
}: {
  selectedPersons: string[]

  claims: (RDSCStats & { persons: string })[]
}) => {
  const filtered = useMemo(() => {
    let filtered = claims

    if (selectedPersons.length) {
      filtered = filtered.filter((it: RDSCStats & { persons: string }) => {
        return (
          it.persons &&
          it.persons
            .split(', ')
            .find((person) => selectedPersons.includes(person))
        )
      })
    }

    return filtered
  }, [selectedPersons, claims])

  return filtered
}

const usePersons = (claims: (RDSCStats & { persons: string })[]) => {
  const [selectedPersons, setPersons] = useState<string[]>([])

  const resetPersons = () => setPersons([])

  const persons = usePersonsOfClaims(claims)

  const addPerson = useCallback(
    (person: string) => {
      const persons = uniq([...selectedPersons, person])
      setPersons(persons)
    },
    [selectedPersons, setPersons]
  )

  const removePerson = useCallback(
    (person: string) => {
      const persons = selectedPersons.filter((s) => s !== person)
      setPersons(persons)
    },
    [selectedPersons, setPersons]
  )

  return {
    selectedPersons,

    addPerson,
    removePerson,
    resetPersons,

    persons,
  }
}

const usePersonsOfClaims = (claims: (RDSCStats & { persons: string })[]) => {
  const persons = useMemo(() => {
    const persons: string[] = []

    for (const claim of claims) {
      if (claim.persons) {
        const claimPersons = claim.persons.split(', ')
        if (claimPersons.length) {
          for (const person of claimPersons) {
            if (!persons.includes(person)) {
              persons.push(person)
            }
          }
        }
      }
    }

    return persons
  }, [claims])

  return persons
}
