import { useCallback, useEffect } from 'react'

export default function useEscapeKeyCallback(
  callback: () => void | Promise<void>
) {
  const handleEscapeKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        void callback()
      }
    },
    [callback]
  )

  useEffect(() => {
    document.removeEventListener('keydown', handleEscapeKeyPress)
    document.addEventListener('keydown', handleEscapeKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [handleEscapeKeyPress])
}
