import { useMemo } from 'react'

import { RDSCClaim } from '../../types'

type props = {
  selectedRowsData: RDSCClaim[]
  claims: RDSCClaim[]
}

export const useTableSelectedShipments = ({
  selectedRowsData,
  claims,
}: props) => {
  const selectedShipmentIds = selectedRowsData
    .map((it) => it.shipmentId || '')
    .filter((it) => it !== '')

  const selectedShipmentsData = useMemo(() => {
    return claims.filter(
      (it) => it.shipmentId && selectedShipmentIds.includes(it.shipmentId)
    )
  }, [claims, selectedShipmentIds])

  return { selectedShipmentIds, selectedShipmentsData }
}
