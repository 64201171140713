import { useMemo } from 'react'

import { PriorityEntry, priorityObjectEntryFind } from '../../lib/array'
import { RDSCClaim } from '../../types'
import { PRODUCT_STATUSES, REFUND_STATUSES } from '../constants'

export const useClaimsWithStatus = (
  data: RDSCClaim[],
  statusPriorities: PriorityEntry[]
) => {
  const withStatus = useMemo(() => {
    const dataWithStatus: (RDSCClaim & { status?: string })[] = []

    const refundStatusIdkey = 'amzRefundStatusId'
    const reconcileStatusIdkey = 'reconcileStatusId'

    for (const claim of data) {
      const { key, object } = priorityObjectEntryFind<RDSCClaim>(
        [claim],
        statusPriorities
      )

      let status: string | undefined

      if (key === refundStatusIdkey && object.amzRefundStatusId) {
        status = REFUND_STATUSES[object.amzRefundStatusId]
      } else if (key === reconcileStatusIdkey && object.reconcileStatusId) {
        status = PRODUCT_STATUSES[object.reconcileStatusId]
      }

      dataWithStatus.push(Object.assign({}, claim, { status }))
    }

    return dataWithStatus
  }, [data])

  return withStatus
}
