import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

interface ErrorData {
  message: string
}

interface ErrorWithMessage {
  data?: ErrorData
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError | undefined
) {
  if (error === undefined) return undefined

  let errorMessage: string | undefined = undefined

  const errorWithMessage = error as ErrorWithMessage

  if (errorWithMessage.data !== undefined) {
    if (errorWithMessage.data && errorWithMessage.data.message) {
      errorMessage = errorWithMessage.data.message
    }
  }

  return errorMessage
}
