import { useEffect, useRef, useState } from 'react'

export const useHasChanged = <T>(prop: T) => {
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  if (hasChanged) {
    setHasChanged(false)
  }

  const prevProp = useRef<T>(prop)
  useEffect(() => {
    if (prop !== prevProp.current) {
      setHasChanged(true)
    }
  }, [prop, prevProp, setHasChanged])

  return hasChanged
}
