import { useCallback, useState } from 'react'

import { RDSCClaim } from '../../../types'
import useCallbackOnChange from '../../lib/hooks/callback-on-change'
import { getErrorMessage } from '../../lib/rtk-query'
import useUpdateRefundsDetails from '../update-refunds-details'

export const useApplyChanges = ({
  selectedShipmentIds,
  selectedShipmentsData,
  selectedCustomerId,
  unselectRows,
}: {
  selectedShipmentIds: string[]
  selectedShipmentsData: RDSCClaim[]
  selectedCustomerId: number
  unselectRows: () => void
}) => {
  const [warnCaseId, setWarnCaseId] = useState<boolean>(false)
  const [action, setAction] = useState<string | 0>(0)
  const [isDisplayingConfirmation, setIsDisplayingConfirmation] =
    useState<boolean>(false)

  const handleSelectedShipmentIdsChange = useCallback(() => {
    setWarnCaseId(false)
    setIsDisplayingConfirmation(false)
    setAction(0)
    setDetails({})
  }, [setWarnCaseId, setIsDisplayingConfirmation])

  useCallbackOnChange<string[]>(
    selectedShipmentIds,
    handleSelectedShipmentIdsChange
  )

  const { setDetails, details, applyRefundsDetails, request } =
    useUpdateRefundsDetails({ selectedRowsData: selectedShipmentsData })

  const handleSelectedCustomerIdChange = useCallback(() => {
    setAction(0)
    setDetails({})
  }, [setAction, setDetails])

  useCallbackOnChange<number>(
    selectedCustomerId,
    handleSelectedCustomerIdChange
  )

  const setNotes = (notes: string | undefined) =>
    setDetails({ ...details, notes })

  const setCaseId = (caseId: string | undefined) =>
    setDetails({ ...details, caseId: caseId ? caseId.trim() : caseId })

  const setIsUploadRPC = (isUploadRPC: boolean) => {
    if (isUploadRPC) {
      setDetails({ ...details, isUploadRPC })
    } else {
      const newDetails = { ...details, isUploadRPC: undefined }
      setDetails(newDetails)
    }
  }

  const selectAction = (selectedAction: string | 0) => {
    setAction(selectedAction)

    if (selectedAction !== 0) {
      if (selectedAction.includes('reconcile')) {
        const reconcileStatusId = Number(selectedAction.split('-')[1])

        const newDetails = {
          ...details,
          reconcileStatusId,
          refundStatusId: undefined,
          isUploadRPC: undefined,
          caseId: undefined,
        }

        setDetails(newDetails)
      } else if (selectedAction.includes('refund')) {
        const refundStatusId = Number(selectedAction.split('-')[1])

        const newDetails = {
          ...details,
          refundStatusId,
          reconcileStatusId: undefined,
          isUploadRPC: undefined,
          caseId: undefined,
        }

        setDetails(newDetails)
      }
    } else {
      const newDetails = {
        ...details,
        refundStatusId: undefined,
        reconcileStatusId: undefined,
        isUploadRPC: undefined,
        caseId: undefined,
      }

      setDetails(newDetails)
    }
  }

  const applyChanges = useCallback(async () => {
    setWarnCaseId(false)

    if (selectedShipmentIds.length === 0) return

    if (details.refundStatusId === 2) {
      if (details.caseId === '' || details.caseId === undefined) {
        setWarnCaseId(true)
        return
      }

      if (!isDisplayingConfirmation) {
        setIsDisplayingConfirmation(true)
        return
      }
    }

    if (details.reconcileStatusId === 1) {
      if (!isDisplayingConfirmation) {
        setIsDisplayingConfirmation(true)
        return
      }
    }

    await applyRefundsDetails()
  }, [
    details,
    applyRefundsDetails,
    setAction,
    unselectRows,
    isDisplayingConfirmation,
    setIsDisplayingConfirmation,
    setWarnCaseId,
  ])

  const [isDisplayingSuccess, setIsDisplayingSuccess] = useState<boolean>(false)
  const [isDisplayingError, setIsDisplayingError] = useState<boolean>(false)

  const { isSuccess, isError, error } = request

  const handleRequestIsSuccessChange = useCallback(() => {
    if (isSuccess) {
      setIsDisplayingSuccess(true)
      setTimeout(() => {
        setIsDisplayingSuccess(false)
      }, 5000)

      setTimeout(() => {
        setIsDisplayingConfirmation(false)
        setAction(0)
        setDetails({})
        if (action !== 0) {
          void unselectRows()
        }
      }, 1000)
    }
  }, [setIsDisplayingSuccess, setAction, setDetails, unselectRows, isSuccess])

  useCallbackOnChange(isSuccess, handleRequestIsSuccessChange)

  const handleRequestIsErrorChange = useCallback(() => {
    if (isError) {
      setIsDisplayingError(true)
      setTimeout(() => setIsDisplayingError(false), 5000)
    }
  }, [setIsDisplayingError, isError])

  useCallbackOnChange(isError, handleRequestIsErrorChange)

  return {
    warnCaseId,
    selectAction,
    selectedAction: action,
    setCaseId,
    setIsUploadRPC,
    notes: details.notes,
    setNotes,
    caseId: details.caseId,
    applyChanges,
    request,
    details,
    isDisplayingError,
    errorMessage: getErrorMessage(error),
    isDisplayingSuccess,
    isDisplayingConfirmation,
    setIsDisplayingConfirmation,
  }
}
