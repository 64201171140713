import React, { MouseEvent } from 'react'

import { toDollarString } from '../../../lib/string'
import { ClipboardCopyCell } from '../../lib/components/clipboard-copy-cell'
import { Column } from '../../lib/hooks/table'

export default [
  {
    id: 'customerId',
    header: 'Customer ID',
    accessorKey: 'customerId',
    sort: 'string',
    cell: (obj) => {
      return (
        <ClipboardCopyCell
          onClick={obj.onClick}
          value={(obj.value as string) || 'N/A'}
        />
      )
    },
  },
  {
    id: 'businessName',
    header: 'Business Name',
    accessorKey: 'businessName',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'person',
    header: 'Person',
    accessorKey: 'persons',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'nNewClaims',
    header: 'New',
    accessorKey: 'nNewClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'nPressingNewClaims',
    header: 'Pressing New',
    accessorKey: 'nPressingNewClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'nRequestedClaims',
    header: 'Requested Claims',
    accessorKey: 'nRequestedClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'nPressingRequestedClaims',
    header: 'Pressing Requested',
    accessorKey: 'nPressingRequestedClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'nSubmittedClaims',
    header: 'Submitted',
    accessorKey: 'nSubmittedClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    header: 'Submitted Value',
    id: 'valueSubmittedClaims',
    accessorKey: 'valueSubmittedClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={toDollarString((obj.value as number) * 100)}
      />
    ),
  },
  {
    header: 'Reimbursed',
    id: 'nReimbursedClaims',
    accessorKey: 'nReimbursedClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    header: 'Remibursed Value',
    id: 'valueReimbursedClaims',
    accessorKey: 'valueReimbursedClaims',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={toDollarString((obj.value as number) * 100)}
      />
    ),
  },
  {
    header: '',
    id: 'open',
    accessorKey: 'customerId',
    cell: (obj) => (
      <button
        className="btn btn-sm btn-primary"
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          return obj.openFn ? obj.openFn(obj.value) : undefined
        }}
      >
        Open
      </button>
    ),
  },
] as Column[]
