import rdscApi from './../../reducers/request/rdsc'
import { RpmAccountCustomerAccountMapping } from '../../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    getRpmAccountCustomerAccountMappings: build.query<
      RpmAccountCustomerAccountMapping[],
      number | void
    >({
      query: () => ({
        url: 'rpm-account-customer-account-mappings',
        method: 'GET',
      }),
      providesTags: ['AccountMappings'],
    }),
  }),
})

export const { useGetRpmAccountCustomerAccountMappingsQuery } = extension
export default extension
