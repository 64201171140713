import { omit, orderBy } from 'lodash'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { ChevronsDown, ChevronsUp } from 'react-feather'

type Opts<T> = {
  select?: 'multiple' | 'single'
  openClick?: (id: number, row: (T & { _id: number }) | undefined) => void
  defaultSort?: string[]
  selectOnOpen?: boolean
  scrollingDisabled?: boolean
}

export type CellProps = {
  value: string | number
  onClick: () => void
  openFn?: (arg0: string | number) => void
}

export type Column = {
  id: string
  header: string | ((props: CellProps) => string | JSX.Element)
  accessorKey: string
  sort?: string
  cell?: (props: CellProps) => string | JSX.Element
}

export default function useTable<T>(
  data: T[],
  columns: Column[],
  opts: Opts<T> = {}
): [JSX.Element, { selectedRowsData: T[]; unselectRows: () => void }] {
  const { select, openClick, defaultSort, selectOnOpen, scrollingDisabled } =
    opts

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const indexed = useMemo(() => {
    return data.map((d, i) => ({ _id: i, ...d } as T & { _id: number }))
  }, [data])

  const selectedRowsData = useMemo(() => {
    return indexed
      .filter((item) => selectedRows.includes(item._id))
      .map((item) => omit(item, ['_id']) as T)
  }, [indexed, selectedRows])

  const openRow = useCallback(
    (id: number) => {
      if (selectOnOpen) {
        setSelectedRows([id])
      }
      return openClick
        ? openClick(
            id,
            indexed.find((it) => it._id === id)
          )
        : undefined
    },
    [openClick, indexed]
  )

  const unselectRows = () => setSelectedRows([])

  const toggleRow = useCallback(
    (rowId: number) => {
      if (!select) return
      if (selectedRows.includes(rowId)) {
        if (select === 'single') {
          setSelectedRows([])
          return
        }

        setSelectedRows(selectedRows.filter((num) => num !== rowId))
      } else {
        if (select === 'single') {
          setSelectedRows([rowId])
          return
        }

        setSelectedRows([...selectedRows, rowId])
      }
    },
    [setSelectedRows, selectedRows, select]
  )

  const [sorted, sortColumns, handleColumnClick] = useTableColumnSort<
    T & { _id: number }
  >(indexed, columns, defaultSort)

  const table = (
    <MemoTable<T>
      {...{
        data: sorted,
        columns,
        sortColumns,
        openRow,
        handleColumnClick,
        selectedRows,
        toggleRow,
        scrollingDisabled,
      }}
    />
  )

  return [table, { selectedRowsData, unselectRows }]
}

const MemoTable = memo(Table) as typeof Table

type props<T> = {
  data: (T & { _id: number })[]
  columns: Column[]
  sortColumns: string[]
  handleColumnClick: (colId: string) => void
  selectedRows: number[]
  toggleRow: (rowId: number) => void
  openRow: (arg0: string | number) => void
  scrollingDisabled?: boolean
}

function Table<T>({
  data = [],
  columns,
  sortColumns,
  selectedRows,
  handleColumnClick,
  openRow,
  toggleRow,
  scrollingDisabled,
}: props<T>) {
  return (
    <table className="table">
      <thead
        style={{
          top: 0,
          zIndex: 2,
          backgroundColor: 'white',
          boxShadow: 'inset 0 0px 0 #ced4da, inset 0 -1px 0 #ced4da',
          ...(scrollingDisabled ? {} : { position: 'sticky' }),
        }}
        className="prevent-select"
      >
        <tr>
          {columns.map((col, i) => (
            <th
              scope="col"
              onClick={() => col.sort && handleColumnClick(col.id)}
              key={i}
              className="fw-bolder"
            >
              {col.header
                ? typeof col.header === 'string'
                  ? col.header
                  : col.header({} as CellProps)
                : null}
              {sortColumns.includes(col.id) ? ' 🔼' : null}
              {sortColumns.includes(`!${col.id}`) ? ' 🔽' : null}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => {
          return (
            <tr
              className={`${
                selectedRows.includes(item._id) ? 'table-info' : ''
              }`}
              onClick={() => toggleRow(item._id)}
              key={i}
            >
              {columns.map((col, i) => {
                const props: CellProps = {
                  value: item[col.accessorKey] as string | number,
                  onClick: () => toggleRow(item._id),
                }

                if (col.id === 'open') {
                  props.openFn = openRow
                }

                if (col.cell) {
                  return <td key={i}>{col.cell(props)}</td>
                }

                return (
                  <td
                    key={i}
                    className={`col.preventSelect ? 'prevent-select':''`}
                    onClick={() => toggleRow(item._id)}
                  >
                    {item[col.accessorKey]}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const useColumnSort = (defaultSort: string[] = []) => {
  const [sortColumns, setSortColumns] = useState<string[]>(defaultSort)

  const handleColumnClick = useCallback(
    (columnName: string) => {
      setSortColumns((prevSortColumns) => {
        const columnExistsIndex = prevSortColumns.findIndex(
          (col) => col === columnName
        )

        const reverseColumnExistsIndex = prevSortColumns.findIndex(
          (col) => col === `!${columnName}`
        )

        if (columnExistsIndex !== -1) {
          const updatedColumns = [...prevSortColumns]
          updatedColumns[columnExistsIndex] = `!${columnName}` // Add reverse sort
          return updatedColumns
        }

        if (reverseColumnExistsIndex !== -1) {
          const updatedColumns = [...prevSortColumns]
          updatedColumns.splice(reverseColumnExistsIndex, 1) // Remove the column from the array
          return updatedColumns
        }

        return [columnName, ...prevSortColumns] // Add the column to the array
      })
    },
    [setSortColumns]
  )

  return [sortColumns, handleColumnClick] as const
}

function useTableColumnSort<T>(
  data: T[],
  columns: Column[],
  defaultSort?: string[]
) {
  const [sortColumns, handleColumnClick] = useColumnSort(defaultSort)

  const sorted = useMemo(() => {
    const sortKeyGets = sortColumns
      .map((sc) => sc.replace('!', ''))
      .filter((sc) => columns.find((col) => col.id === sc) !== undefined)
      // 'natural-orderby' requires a getter function for the value, rather than the key
      .map(
        (sc) => (v: T) =>
          v[
            columns.find((col) => col.id === sc)?.accessorKey || 'never' // we filter undefined
          ] as () => string | number | Date
      )

    const sortOrders = sortColumns.map((sc) =>
      sc.startsWith('!') ? 'desc' : 'asc'
    )

    return orderBy<T>(data, sortKeyGets, sortOrders)
  }, [data, sortColumns])

  return [sorted, sortColumns, handleColumnClick] as const
}
