import React, { CSSProperties, ReactNode } from 'react'

export default function Modal({
  title,
  onClose,
  onSubmit,
  body,
  children,
  isFloating = false,
  className,
  style = {},
}: {
  title: ReactNode | string
  onClose?: () => void
  onSubmit?: () => void
  body?: ReactNode
  children?: ReactNode
  isFloating?: boolean
  className?: string
  style?: CSSProperties
}) {
  return (
    <div
      className={['modal show', className].join(' ')}
      tabIndex={-1}
      style={{ display: 'block', ...style }}
    >
      <div
        className={`modal-dialog modal-dialog-centered ${
          isFloating ? '' : 'modal-fullscreen'
        }`}
      >
        <div className="modal-content border border-secondary rounded shadow-lg">
          <div className="modal-header bg-light">
            <h5 className="modal-title">{title}</h5>
            {onClose !== undefined ? (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            ) : null}
          </div>
          <div className="modal-body">{body || children}</div>
          <div className="modal-footer bg-light">
            {onClose !== undefined ? (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            ) : null}
            {onSubmit !== undefined ? (
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={onSubmit}
              >
                Submit
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
