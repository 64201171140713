import { subMonths } from 'date-fns'
import { useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { RDSCClaim } from '../../../types'
import columnsNew from '../../config/table-columns/claims-new'
import columnsRequested from '../../config/table-columns/claims-requested'
import columnsResolved from '../../config/table-columns/claims-resolved'
import columnsSubmitted from '../../config/table-columns/claims-submitted'
import useDateRange from '../../lib/hooks/date-range'
import { useHasChanged } from '../../lib/hooks/has-changed'
import useTable from '../../lib/hooks/table'
import { useAssignAsteriskClaimStatus } from '../assign-asterisk-claim-status'
import { useClaimsForTab } from '../claims-for-tab'
import { useReimbursementStats } from '../reimbursement-stats'
import { useGetClaimsQuery } from '../request/rdsc-claims'
import { useSelectStatusIdClaims } from '../select-status-id-claims'
import { useSelectStoreNameClaims } from '../select-store-name-claims'
import { useShipmentsByIdWithStatus } from '../shipments-by-id-with-status'
import { useStatusIdsForTab } from '../status-ids-for-tab'
import { useTableSelectedShipments } from '../table-selected-shipment'

const columnsLookup = {
  new: columnsNew,
  requested: columnsRequested,
  submitted: columnsSubmitted,
  resolved: columnsResolved,
}

const refundStatusIdkey = 'amzRefundStatusId'
const reconcileStatusIdkey = 'reconcileStatusId'

const statusPrioritiesLookup = {
  new: [],
  requested: [
    { key: reconcileStatusIdkey, value: 3 },
    { key: reconcileStatusIdkey, value: 2 },
    { key: reconcileStatusIdkey, value: 1 },
  ],
  submitted: [
    { key: reconcileStatusIdkey, value: 2 },
    { key: refundStatusIdkey, value: 2 },
  ],
  resolved: [
    { key: refundStatusIdkey, value: 6 },
    { key: refundStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 6 },
    { key: reconcileStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 7 },
    { key: refundStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 9 },
    { key: reconcileStatusIdkey, value: 5 },
    { key: refundStatusIdkey, value: 7 },
    { key: reconcileStatusIdkey, value: 8 },
  ],
}

export type RDSCClaimWithStatus = RDSCClaim & {
  status: string | undefined
}

type props = {
  selectedCustomerId: number
  selectShipmentId: (id: string) => void
  tabId: 'new' | 'requested' | 'submitted' | 'resolved'
}

export default function useClaims({
  selectedCustomerId,
  selectShipmentId,
  tabId,
}: props) {
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false)

  const {
    data = [],
    isLoading,
    isFetching,
    isError,
  } = useGetClaimsQuery(selectedCustomerId, {
    skip: selectedCustomerId === 0,
  })

  const { refundStatusIds, reconcileStatusIds } = useStatusIdsForTab(
    data,
    tabId
  )

  const claimsForTab = useClaimsForTab(!isError ? data : [], tabId)

  const [searchQuery, setBouncingSearchQuery] = useState('')

  const setSearchQuery = useDebouncedCallback((value: string) => {
    setBouncingSearchQuery(value)
  }, 300)

  let filtered: RDSCClaim[]

  const searchedClaims = useMemo(() => {
    return claimsForTab.filter((it) => {
      const values = Object.values(it).join('').toLowerCase()
      const includes = values.includes(searchQuery.toLowerCase())
      return includes
    })
  }, [claimsForTab, searchQuery])

  filtered = searchedClaims

  const {
    claims: selectedStoreNameClaims,

    selectedStoreNames,

    addStoreName,
    removeStoreName,
    resetStoreNames,

    storeNames,
  } = useSelectStoreNameClaims(filtered)

  filtered = selectedStoreNameClaims

  const {
    claims: selectedStatusIdClaims,

    selectedReconcileStatusIds,
    selectedRefundStatusIds,

    addReconcileStatusId,
    removeReconcileStatusId,

    addRefundStatusId,
    removeRefundStatusId,

    resetStatusIds,
  } = useSelectStatusIdClaims({
    claims: filtered,
    tab: tabId,
  })

  filtered = selectedStatusIdClaims

  const dateRange = useMemo(() => {
    let mostRecentDate = Math.floor(new Date().getTime() / 1000)
    let leastRecentDate = Math.floor(new Date().getTime() / 1000)

    for (const claim of claimsForTab) {
      const claimDate = claim.shipmentCreatedDate || claim.createdDate
      if (claimDate) {
        if (claimDate > mostRecentDate) {
          mostRecentDate = claimDate
        }

        if (claimDate < leastRecentDate) {
          leastRecentDate = claimDate
        }
      }
    }

    return {
      min: new Date(leastRecentDate * 1000),
      max: new Date(mostRecentDate * 1000),
    }
  }, [claimsForTab])

  const today = useMemo(() => new Date(), [])
  const NineMonthsAgo = useMemo(() => subMonths(today, 9), [today])

  const defaultStartDate = useMemo(
    () =>
      NineMonthsAgo.getTime() > dateRange.min.getTime()
        ? NineMonthsAgo
        : dateRange.min,
    [NineMonthsAgo, dateRange.min]
  )

  const [{ startDate, endDate }, setDateRange] = useDateRange({
    defaultStartDate,
    defaultEndDate: dateRange.max,
  })

  const hasCustomerChanged = useHasChanged(selectedCustomerId)
  if (hasCustomerChanged) {
    resetStoreNames()
    resetStatusIds()
    setSearchQuery('')
    setDateRange([undefined, undefined])
  }

  const claimsFilteredByDate = useMemo(() => {
    if (tabId !== 'resolved') return filtered

    const startTime =
      startDate !== undefined ? Math.floor(startDate.getTime() / 1000) : 0

    const endTime =
      endDate !== undefined ? Math.floor(endDate.getTime() / 1000) : 0

    const filteredByDate: RDSCClaim[] = []
    for (const claim of filtered) {
      const claimDate = claim.shipmentCreatedDate || claim.createdDate
      if (claimDate) {
        if (endTime !== 0 && startTime !== 0) {
          if (claimDate <= endTime && claimDate >= startTime) {
            filteredByDate.push(claim)
          }
        } else if (endDate !== undefined && startTime === 0) {
          if (claimDate <= endTime) {
            filteredByDate.push(claim)
          }
        } else if (startDate !== undefined && endTime === 0) {
          if (claimDate >= startTime) {
            filteredByDate.push(claim)
          }
        } else {
          filteredByDate.push(claim)
        }
      }
    }

    return filteredByDate
  }, [startDate, endDate, filtered, tabId])

  filtered = claimsFilteredByDate

  let claimsByShipmentId = useShipmentsByIdWithStatus(
    filtered,
    statusPrioritiesLookup[tabId]
  )

  claimsByShipmentId = useAssignAsteriskClaimStatus(
    claimsByShipmentId,
    claimsForTab,
    tabId
  )

  const [table, { selectedRowsData, unselectRows }] = useTable<RDSCClaim>(
    claimsByShipmentId,
    columnsLookup[tabId],
    {
      openClick: (_, row) =>
        row && row.shipmentId ? selectShipmentId(row.shipmentId) : null,
      select: 'multiple',
      selectOnOpen: true,
    }
  )

  const { selectedShipmentIds, selectedShipmentsData } =
    useTableSelectedShipments({
      claims: claimsForTab,
      selectedRowsData,
    })

  const { nReimbursableClaims, reimbursableValue } =
    useReimbursementStats(filtered)

  return {
    isModalShowing,
    setIsModalShowing,

    isLoading,
    isFetching,

    selectedShipmentIds,
    selectedShipmentsData,
    nClaimsByShipmentId: claimsByShipmentId.length,
    claimsForTab,

    nReimbursableClaims,
    reimbursableValue,

    storeNames,
    selectedStoreNames,
    removeStoreName,
    addStoreName,

    table,
    selectedRowsData,
    unselectRows,
    setSearchQuery,

    dateRange,
    setDateRange,

    refundStatusIds,
    selectedRefundStatusIds,
    addRefundStatusId,
    removeRefundStatusId,

    reconcileStatusIds,
    selectedReconcileStatusIds,
    addReconcileStatusId,
    removeReconcileStatusId,
  }
}
