import equal from 'deep-equal'
import { useEffect, useRef } from 'react'

export default function useCallbackOnChange<T>(
  prop: T,
  callback: () => void | Promise<void>
) {
  const prevPropRef = useRef<T>(prop)
  useEffect(() => {
    if (!equal(prop, prevPropRef.current)) {
      prevPropRef.current = prop
      void callback()
    }
  }, [prop, prevPropRef, callback])
}
