import { uniq, uniqBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { RDSCClaim } from '../../types'

export const useSelectStoreNameClaims = (claims: RDSCClaim[]) => {
  const {
    selectedStoreNames,

    addStoreName,
    removeStoreName,
    resetStoreNames,

    storeNames,
  } = useStoreNames(claims)

  const selectedClaims = useSelectedStoreNamesClaims({
    selectedStoreNames,

    claims: claims,
  })

  return {
    claims: selectedClaims,

    selectedStoreNames,

    addStoreName,
    removeStoreName,
    resetStoreNames,

    storeNames,
  }
}

const useSelectedStoreNamesClaims = ({
  selectedStoreNames,
  claims,
}: {
  selectedStoreNames: string[]
  claims: RDSCClaim[]
}) => {
  const filtered = useMemo(() => {
    let filtered = claims

    if (selectedStoreNames.length) {
      filtered = filtered.filter((it: RDSCClaim) => {
        return it.businessName && selectedStoreNames.includes(it.businessName)
      })
    }

    return filtered
  }, [selectedStoreNames, claims])

  return filtered
}

const useStoreNames = (claims: RDSCClaim[]) => {
  const [selectedStoreNames, setStoreNames] = useState<string[]>([])

  const resetStoreNames = () => setStoreNames([])

  const storeNames = useStoreNamesOfClaims(claims)

  const addStoreName = useCallback(
    (storeName: string) => {
      const storeNames = uniq([...selectedStoreNames, storeName])
      setStoreNames(storeNames)
    },
    [selectedStoreNames, setStoreNames]
  )

  const removeStoreName = useCallback(
    (storeName: string) => {
      const storeNames = selectedStoreNames.filter((s) => s !== storeName)
      setStoreNames(storeNames)
    },
    [selectedStoreNames, setStoreNames]
  )

  return {
    selectedStoreNames,

    addStoreName,
    removeStoreName,
    resetStoreNames,

    storeNames,
  }
}

const useStoreNamesOfClaims = (claims: RDSCClaim[]) => {
  const storeNames = useMemo(() => {
    return uniqBy(claims, 'businessName').map((it) => it.businessName)
  }, [claims])

  return storeNames
}
