import { uniqBy } from 'lodash'
import { useMemo } from 'react'

import { RDSCClaim } from '../../types'
import { TabId } from '../config/claim-tabs'
import { tabStatusesLookup } from '../config/tab-statuses'

export type RDSCClaimWithStatus = RDSCClaim & {
  status: string | undefined
}

export const useAssignAsteriskClaimStatus = (
  filteredClaims: RDSCClaimWithStatus[],
  allClaims: RDSCClaim[],
  tab: TabId
) => {
  const marked = useMemo(() => {
    return filteredClaims.map((c: RDSCClaimWithStatus) => {
      const claims = allClaims.filter((it) => it.shipmentId === c.shipmentId)

      if (claims.length > 1) {
        const hasMultipleStatuses = checkClaimsHasMultipleRelevantStatuses(
          claims,
          tabStatusesLookup[tab][0],
          tabStatusesLookup[tab][1]
        )

        if (hasMultipleStatuses) {
          c.status = c.status + '*'
        }
      }

      return c
    })
  }, [filteredClaims, allClaims])

  return marked
}

const checkClaimsHasMultipleRelevantStatuses = (
  claims: RDSCClaim[],
  refundStatusIds: (number | undefined)[],
  reconcileStatusIds: (number | undefined)[]
) => {
  const claimsWithRelevantStatus = claims.filter(
    (sd) =>
      reconcileStatusIds.includes(sd.reconcileStatusId ) ||
      refundStatusIds.includes(sd.amzRefundStatusId )
  )

  const uniqStatuses = uniqBy(
    claimsWithRelevantStatus,
    (sd: RDSCClaim) => `${sd.reconcileStatusId}${sd.amzRefundStatusId}`
  )

  const hasMultipleStatuses = uniqStatuses.length > 1

  return hasMultipleStatuses
}
