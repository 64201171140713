export const priorityKeyValueFind = <T>(
  arr: T[],
  key: keyof T,
  values: T[keyof T][]
): T[keyof T] | undefined => {
  for (const value of values) {
    const found = arr.find((v) => v[key] === value)
    if (found) return found[key]
  }
  return undefined
}

export type PriorityEntry = {
  key: string | number
  value?: number | string
}

export const priorityObjectEntryFind = <T>(
  values: T[],
  priorityList: PriorityEntry[]
): { key: string | number; object: T } => {
  let object: T | undefined
  let key: string | number

  for (const p of priorityList) {
    const found = values.find((value) => value[p.key] === p.value)
    if (found) {
      object = found
      key = p.key
      break
    }
  }

  return { key, object }
}
