import { uniq } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { RDSCClaim } from '../../types'
import { TabId } from '../config/claim-tabs'

type props = {
  claims: RDSCClaim[]
  tab: TabId
}

export const useSelectStatusIdClaims = ({ claims, tab }: props) => {
  const {
    selectedStatusIds: selectedReconcileStatusIds,
    addStatusId: addReconcileStatusId,
    removeStatusId: removeReconcileStatusId,
    resetStatusIds: resetReconcileStatusIds,
  } = useStatusIds()

  const {
    selectedStatusIds: selectedRefundStatusIds,
    addStatusId: addRefundStatusId,
    removeStatusId: removeRefundStatusId,
    resetStatusIds: resetRefundStatusIds,
  } = useStatusIds()

  const selectedClaims = useSelectedStatusIdClaims({
    selectedReconcileStatusIds,
    selectedRefundStatusIds,
    tab,

    claims,
  })

  const resetStatusIds = () => {
    resetReconcileStatusIds()
    resetRefundStatusIds()
  }

  return {
    claims: selectedClaims,

    selectedReconcileStatusIds,
    selectedRefundStatusIds,

    addReconcileStatusId,
    removeReconcileStatusId,

    addRefundStatusId,
    removeRefundStatusId,

    resetStatusIds,
  }
}

const useSelectedStatusIdClaims = ({
  selectedReconcileStatusIds,
  selectedRefundStatusIds,
  tab,

  claims,
}: {
  selectedReconcileStatusIds: number[]
  selectedRefundStatusIds: number[]
  tab: TabId

  claims: RDSCClaim[]
}) => {
  const filtered = useMemo(() => {
    let filtered = claims

    if (selectedReconcileStatusIds.length || selectedRefundStatusIds.length) {
      if (tab === 'requested') {
        filtered = filtered.filter((it: RDSCClaim) => {
          return (
            selectedReconcileStatusIds.includes(
              it.reconcileStatusId 
            ) && it.amzRefundStatusId === 1
          )
        })
      } else if (tab === 'submitted') {
        filtered = filtered.filter((it: RDSCClaim) => {
          if (
            selectedRefundStatusIds.length &&
            selectedReconcileStatusIds.length === 0
          ) {
            return (
              selectedRefundStatusIds.includes(
                it.amzRefundStatusId 
              ) && it.reconcileStatusId === undefined
            )
          }

          if (
            selectedRefundStatusIds.length &&
            selectedReconcileStatusIds.length
          ) {
            return (
              selectedReconcileStatusIds.includes(
                it.reconcileStatusId 
              ) ||
              selectedRefundStatusIds.includes(it.amzRefundStatusId )
            )
          }

          return (
            selectedReconcileStatusIds.includes(
              it.reconcileStatusId 
            ) && it.amzRefundStatusId === 2
          )
        })
      } else {
        filtered = filtered.filter((it: RDSCClaim) => {
          return (
            selectedReconcileStatusIds.includes(
              it.reconcileStatusId 
            ) ||
            selectedRefundStatusIds.includes(it.amzRefundStatusId )
          )
        })
      }
    }

    return filtered
  }, [selectedReconcileStatusIds, selectedRefundStatusIds, claims, tab])

  return filtered
}

const useStatusIds = () => {
  const [selectedStatusIds, setStatusIds] = useState<number[]>([])

  const resetStatusIds = () => setStatusIds([])

  const addStatusId = useCallback(
    (statusId: number) => {
      setStatusIds(uniq([...selectedStatusIds, statusId]))
    },
    [selectedStatusIds, setStatusIds]
  )

  const removeStatusId = useCallback(
    (statusId: number) => {
      setStatusIds(selectedStatusIds.filter((s) => s !== statusId))
    },
    [selectedStatusIds, setStatusIds]
  )

  return {
    selectedStatusIds,

    addStatusId,
    removeStatusId,
    resetStatusIds,
  }
}
