import React from 'react'

import { formatUnix } from '../../../lib/date'
import { toDollarString } from '../../../lib/string'
import { ClipboardCopyCell } from '../../lib/components/clipboard-copy-cell'
import { Column } from '../../lib/hooks/table'

export default [
  {
    id: 'shipmentId',
    header: 'Shipment ID',
    accessorKey: 'shipmentId',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'fnsku',
    header: 'FNSKU',
    accessorKey: 'fnsku',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'sku',
    header: 'SKU',
    accessorKey: 'sku',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'productName',
    header: 'Product Name',
    accessorKey: 'productName',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'shipDate',
    header: 'Ship Date',
    accessorKey: 'shipmentCreatedDate',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={formatUnix(obj.value as number) ?? 'N/A'}
      />
    ),
  },
  {
    id: 'deadline',
    header: 'Deadline',
    accessorKey: 'deadline',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={formatUnix(obj.value as number) ?? 'N/A'}
      />
    ),
  },
  {
    id: 'estimatedTotal',
    header: 'Est. Value',
    accessorKey: 'estimatedTotal',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={toDollarString((obj.value as number) * 100)}
      />
    ),
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={String(obj.value ?? 'N/A')}
      />
    ),
  },
] as Column[]
