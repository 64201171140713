import React, { useMemo } from 'react'

import claimsTabs, { Tab, TabId } from './../config/claim-tabs'
import Modal from './../lib/components/modal'
import Spinner from './../lib/components/spinner-container'
import Tabs from './../lib/components/tabs'
import CaseManagerEmailInfo from './case-manager-email-info'
import NewClaims from './claims-new'
import RequestedClaims from './claims-requested'
import ResolvedClaims from './claims-resolved'
import SubmittedClaims from './claims-submitted'
import { RDSCStats } from '../../types'
import usePersons from '../hooks/persons'
import { useGetClaimsQuery } from '../hooks/request/rdsc-claims'

export default function ClaimsModal({
  selectedCustomerStats,
  selectedTab,
  selectTab,
  selectedCustomerId,
  selectCustomerId,
  selectShipmentId,
}: {
  selectedCustomerStats?: RDSCStats | null
  selectedCustomerId: number
  selectedTab: TabId | undefined
  selectTab: (tab: TabId | undefined) => void
  selectCustomerId: (id: number) => void
  selectShipmentId: (id: string) => void
}) {
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetClaimsQuery(selectedCustomerId, {
    skip: selectedCustomerId === 0,
  })

  const { persons, personsForCustomer, addPerson, removePerson } = usePersons({
    customerAccountId: selectedCustomerId,
  })

  const personSelectionList = useMemo(() => {
    return persons.filter((person) => {
      const alreadyAssigned =
        personsForCustomer.find((pfc) => pfc.accountId === person.accountId) !==
        undefined

      return !alreadyAssigned
    })
  }, [persons, personsForCustomer])

  const { username, password } = useMemo(() => {
    if (!data || !data.length) return {}
    return data[0]
  }, [data])

  const customerModalTitle =
    selectedCustomerId !== 0 &&
    selectedCustomerStats &&
    selectedCustomerStats !== null
      ? `${selectedCustomerId} — ${selectedCustomerStats.businessName} — ${
          claimsTabs.find((ct: Tab) => ct.id === selectedTab)?.title
        } Claims`
      : ''

  return (
    <Modal
      {...{
        title: customerModalTitle,
        onClose: () => selectCustomerId(0),
      }}
    >
      <div className="d-flex flex-column h-100">
        <div className="d-flex flex-row flex-wrap">
          <div className="me-3">
            <CaseManagerEmailInfo {...{ username, password }} />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-row justify-content-end">
              {personsForCustomer.map((person, i) => {
                return (
                  <button
                    type="button"
                    className="me-3 px-5 border-custom btn btn-sm btn-light text-dark"
                    onClick={() => removePerson(person)}
                    key={i}
                  >
                    {person.userName}
                  </button>
                )
              })}
              <div className="dropdown">
                <button
                  className="btn btn-sm btn-light btn-outline-dark border-custom dropdown-toggle"
                  type="button"
                  id="personsForCustomer"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Add Person
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="personsForCustomer"
                >
                  {personSelectionList.map((person, i) => {
                    return (
                      <li key={i}>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => addPerson(person)}
                        >
                          {person.userName}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          tabs={claimsTabs}
          selectedTab={selectedTab}
          onTabSelect={selectTab}
        />
        <div className="h-100 bg-light border-top ps-3 pe-3 pt-2 pb-3 rounded overflow-auto">
          <div
            className={`pb-4 ${
              isLoading || isFetching ? 'h-100 flex-grow-1' : 'd-none'
            }`}
          >
            <Spinner />
            <span className="float-end">Fetching...</span>
          </div>
          <NewClaims
            {...{
              selectedCustomerId,
              selectShipmentId,
              isShowing: selectedTab === 'new',
            }}
          />
          <RequestedClaims
            {...{
              selectedCustomerId,
              selectShipmentId,
              isShowing: selectedTab === 'requested',
            }}
          />
          <SubmittedClaims
            {...{
              selectedCustomerId,
              selectShipmentId,
              isShowing: selectedTab === 'submitted',
            }}
          />
          <ResolvedClaims
            {...{
              selectedCustomerId,
              selectShipmentId,
              isShowing: selectedTab === 'resolved',
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
