import { sumBy } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'

import SKUsNew from './skus-new'
import SKUsRequested from './skus-requested'
import SKUsResolved from './skus-resolved'
import SKUsSubmitted from './skus-submitted'
import { formatUnix } from '../../lib/date'
import { toDollarString } from '../../lib/string'
import { TabId } from '../config/claim-tabs'
import { useClaimsForTab } from '../hooks/claims-for-tab'
import { useGetClaimsQuery } from '../hooks/request/rdsc-claims'
import Modal from '../lib/components/modal'

const skusComponentLookup = {
  new: SKUsNew,
  requested: SKUsRequested,
  submitted: SKUsSubmitted,
  resolved: SKUsResolved,
}

type props = {
  selectedShipmentId: string
  selectShipmentId: Dispatch<SetStateAction<string>>
  selectedCustomerId: number
  selectedTab: TabId | undefined
}

export default function SKUsModal({
  selectedShipmentId,
  selectedCustomerId,
  selectedTab,
  selectShipmentId,
}: props) {
  const SKUsComponent = selectedTab ? skusComponentLookup[selectedTab] : null

  const { data = [] } = useGetClaimsQuery(selectedCustomerId, {
    skip: selectedCustomerId === 0,
  })

  const selectedShipmentData = useMemo(() => {
    return data.filter((it) => it.shipmentId === selectedShipmentId)
  }, [data, selectedShipmentId])

  const selectedShipmentDataForTab = selectedTab
    ? useClaimsForTab(selectedShipmentData, selectedTab)
    : []

  useEffect(() => {
    if (selectedShipmentId !== '' && selectedShipmentDataForTab.length === 0) {
      setTimeout(() => selectShipmentId(''), 1000)
    }
  }, [selectedShipmentDataForTab, selectShipmentId])

  const modalTitle = useModalTitle({
    selectedShipmentDataForTab,
    selectedShipmentId,
  })

  // TODO add call signature to skuscomponent
  return (
    <Modal
      {...{
        title: modalTitle,
        onClose: () => {
          selectShipmentId('')
        },
      }}
    >
      <SKUsComponent
        {...{ data: selectedShipmentDataForTab || [], selectedCustomerId }}
      />
    </Modal>
  )
}

const useModalTitle = ({ selectedShipmentDataForTab, selectedShipmentId }) => {
  const selectedShipmentTotalEstimate = useMemo(
    () =>
      toDollarString(sumBy(selectedShipmentDataForTab, 'estimatedTotal') * 100),
    [selectedShipmentDataForTab]
  )

  const selectedShipmentModalTitle =
    selectedShipmentDataForTab !== null
      ? `${selectedShipmentId} — ${
          selectedShipmentDataForTab.length
            ? selectedShipmentDataForTab[0]?.businessName
            : ''
        } — Deadline:  ${
          selectedShipmentDataForTab.length
            ? formatUnix(selectedShipmentDataForTab[0]?.deadline)
            : ''
        } — Estimated:  ${selectedShipmentTotalEstimate}`
      : ''

  return selectedShipmentModalTitle
}
