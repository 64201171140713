import {
  AnyAction,
  configureStore,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { Dispatch } from 'react'
import { connectRoutes } from 'redux-first-router'

import { rdscApi } from '../reducers/request/rdsc'
import userReducer, { authorize } from '../reducers/user'

const routes = {
  HOME: '/',
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (action.payload.originalStatus === 401) {
        api.dispatch(authorize(false))
        console.warn('We got a rejected action!', action)
      }
    }

    return next(action)
  }

export default function createStore() {
  const { reducer, middleware, enhancer } = connectRoutes(routes)

  const rootReducer = {
    location: reducer,
    user: userReducer,
    [rdscApi.reducerPath]: rdscApi.reducer,
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefault) =>
      getDefault().concat(middleware, rdscApi.middleware, rtkQueryErrorLogger),
    enhancers: [enhancer],
  })

  return store
}

export const store = createStore()

export type AppStore = ReturnType<typeof createStore>
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
