import { useCallback, useEffect, useMemo, useState } from 'react'

import useOverviewTable from './overview-table'
import { TabId } from '../../config/claim-tabs'
import columns from '../../config/table-columns/overview'
import useEscapeKeyCallback from '../../hooks/escape-key-callback'
import useTable from '../../lib/hooks/table'

export default function useOverview() {
  const [selectedCustomerId, selectCustomerId] = useState<number>(0)
  const [selectedShipmentId, selectShipmentId] = useState<string>('')
  const [selectedTab, selectTab] = useState<TabId | undefined>('new')

  const {
    rdscStats,
    rdscStatsIsLoading,

    tableData,

    claimsPersons,
    selectedPersons,
    removePerson,
    addPerson,

    disabledCustomerAccounts,
    nonDisabledCustomerAccounts,
    addDisabledCustomerAccount,
    removeDisabledCustomerAccount,

    setSearchQuery,
  } = useOverviewTable()

  const openClick = useCallback(
    (id: number) => {
      selectCustomerId(id)
    },
    [selectCustomerId]
  )

  const [table, { selectedRowsData }] = useTable(tableData || [], columns, {
    openClick,
    select: 'multiple',
    defaultSort: [
      '!nPressingNewClaims',
      '!nPressingRequestedClaims',
      '!nNewClaims',
      'customerId',
    ],
  })

  const selectedCustomerIds = selectedRowsData.map((r) => r.customerId)

  let rdscStatsData = rdscStats ?? []

  rdscStatsData = rdscStatsData.filter(
    (data) =>
      tableData.find((row) => row.customerId === data.customerId) !== undefined
  )

  if (selectedCustomerIds.length) {
    rdscStatsData = rdscStatsData.filter((data) =>
      selectedCustomerIds.includes(data.customerId)
    )
  }

  useEffect(() => {
    if (selectedCustomerId === 0) {
      selectTab('new')
    }
  }, [selectedCustomerId, selectTab])

  const selectedCustomerStats = useMemo(() => {
    const customerStats = rdscStats
      ? rdscStats.find((stat) => stat.customerId === selectedCustomerId)
      : null

    return customerStats
  }, [rdscStats, selectedCustomerId])

  const handleEscapeKey = useCallback(() => {
    if (selectedShipmentId !== '') {
      selectShipmentId('')
      return
    }

    if (selectedCustomerId !== 0) {
      selectCustomerId(0)
      return
    }
  }, [
    selectedShipmentId,
    selectedCustomerId,
    selectShipmentId,
    selectCustomerId,
  ])

  useEscapeKeyCallback(handleEscapeKey)

  return {
    rdscStatsData,
    rdscStatsIsLoading,

    selectedCustomerStats,

    claimsPersons,
    selectedPersons,
    removePerson,
    addPerson,

    selectedCustomerId,
    selectCustomerId,

    selectedShipmentId,
    selectShipmentId,

    selectedTab,
    selectTab,

    setSearchQuery,

    disabledCustomerAccounts,
    nonDisabledCustomerAccounts,
    addDisabledCustomerAccount,
    removeDisabledCustomerAccount,

    table,
  }
}
