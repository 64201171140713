import rdscApi from './../../reducers/request/rdsc'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    getDisabledCustomerAccounts: build.query<number[], void>({
      query: () => ({
        url: 'disabled-customer-accounts',
        method: 'GET',
      }),
      providesTags: ['DisabledCustomerAccounts'],
    }),
  }),
})

export const { useGetDisabledCustomerAccountsQuery } = extension
export default extension
