import { useCallback, useState } from 'react'

import { RDSCClaim } from '../../../types'
import useCallbackOnChange from '../../lib/hooks/callback-on-change'
import { useHasChanged } from '../../lib/hooks/has-changed'
import useUpdateRefundsDetails from '../update-refunds-details'

export const useApplyChanges = ({
  selectedShipmentsData,
  unselectRows,
  selectedRowsData,
  selectedCustomerId,
}: {
  selectedShipmentsData: RDSCClaim[]
  unselectRows: () => void
  selectedRowsData: RDSCClaim[]
  selectedCustomerId: number
}) => {
  const [action, setAction] = useState<string | 0>(0)

  const { setDetails, details, applyRefundsDetails, request } =
    useUpdateRefundsDetails({ selectedRowsData })

  const customerHasChanged = useHasChanged(selectedCustomerId)
  if (customerHasChanged) {
    setAction(0)
    setDetails({})
  }

  const handleSelectedDataChange = useCallback(() => {
    setAction(0)
    setDetails({})
  }, [setAction, setDetails])

  useCallbackOnChange<RDSCClaim[]>(
    selectedShipmentsData,
    handleSelectedDataChange
  )

  useCallbackOnChange<RDSCClaim[]>(selectedRowsData, handleSelectedDataChange)

  const selectAction = (selectedAction: string | 0) => {
    setAction(selectedAction)

    if (selectedAction !== 0) {
      if (selectedAction.includes('reconcile')) {
        const reconcileStatusId = Number(selectedAction.split('-')[1])

        const newDetails = {
          ...details,
          reconcileStatusId,
        }

        delete newDetails.refundStatusId

        setDetails(newDetails)
      } else if (selectedAction.includes('refund')) {
        const refundStatusId = Number(selectedAction.split('-')[1])

        const newDetails = {
          ...details,
          refundStatusId,
        }

        delete newDetails.reconcileStatusId

        setDetails(newDetails)
      }
    }
  }

  const [isDisplayingSuccess, setIsDisplayingSuccess] = useState<boolean>(false)
  const [isDisplayingError, setIsDisplayingError] = useState<boolean>(false)

  const { isError, isSuccess } = request

  const handleRequestIsSuccessChanged = useCallback(() => {
    if (isSuccess) {
      setIsDisplayingSuccess(true)

      setTimeout(() => {
        setAction(0)
        setDetails({})
        setIsDisplayingSuccess(false)
        void unselectRows()
      }, 1000)
    }
  }, [setIsDisplayingSuccess, unselectRows, setDetails, isSuccess])

  useCallbackOnChange(isSuccess, handleRequestIsSuccessChanged)

  const handleRequestIsErrorChanged = useCallback(() => {
    if (isError) {
      setIsDisplayingError(true)
      setTimeout(() => setIsDisplayingError(false), 5000)
    }
  }, [setIsDisplayingError, isError])

  useCallbackOnChange(isError, handleRequestIsErrorChanged)

  const applyChanges = useCallback(async () => {
    if (action === 0) return

    await applyRefundsDetails()

    // TODO confirmation ?
    //   if (details.reconcileStatusId === 1) {
    //     if (!isDisplayingConfirmation) {
    //       setIsDisplayingConfirmation(true)
    //       return
    //     }
    //   }
  }, [applyRefundsDetails, unselectRows])

  return {
    selectAction,
    selectedAction: action,
    applyChanges,
    request,
    details,
    isDisplayingError,
    isDisplayingSuccess,
  }
}
