import rdscApi from './../../reducers/request/rdsc'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    authenticate: build.query<string, void>({
      query: () => ({
        url: 'authenticate',
        method: 'GET',
        responseHandler: (res) => res.text(),
      }),
    }),
  }),
})

export const { useAuthenticateQuery } = extension
export default extension
