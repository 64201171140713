import React, { useMemo, useState } from 'react'
import { Check, X } from 'react-feather'
import { SpinnerCircularSplit } from 'spinners-react'

import { RDSCClaim } from '../../types'
import columns from '../config/table-columns/submit-confirm'
import { useClaimsForTab } from '../hooks/claims-for-tab'
import { useClaimsWithStatus } from '../hooks/claims-with-status'
import { useGetClaimsQuery } from '../hooks/request/rdsc-claims'
import Modal from '../lib/components/modal'
import useTable from '../lib/hooks/table'
import { RequestStatus } from '../types'

export default function ModalConfirmSubmitClaim({
  selectedCustomerId,
  selectedShipmentsData,
  selectedShipmentIds,
  caseId,
  request,
  isDisplayingError,
  errorMessage,
  isDisplayingSuccess,
  applyChanges,
  setIsDisplayingConfirmation,
}: {
  selectedCustomerId: number
  selectedShipmentsData: RDSCClaim[]
  selectedShipmentIds: string[]
  caseId: string
  request: RequestStatus
  isDisplayingError: boolean
  errorMessage: string | undefined
  isDisplayingSuccess: boolean
  applyChanges: () => Promise<void>
  setIsDisplayingConfirmation: (arg0: boolean) => void
}) {
  const [modalShipmentData] = useState<RDSCClaim[]>(selectedShipmentsData)
  const [modalShipmentIds] = useState<string[]>(selectedShipmentIds)

  const refundStatusIdkey = 'amzRefundStatusId'
  const reconcileStatusIdkey = 'reconcileStatusId'

  const confirmTableData = useClaimsWithStatus(modalShipmentData, [
    { key: reconcileStatusIdkey, value: 3 },
    { key: reconcileStatusIdkey, value: 2 },
    { key: reconcileStatusIdkey, value: 1 },
  ])

  const [table] = useTable<RDSCClaim>(confirmTableData, columns, {
    scrollingDisabled: true,
  })

  const selectedShipmentBusinessName = modalShipmentData.length
    ? modalShipmentData[0].businessName
    : ''

  const {
    data = [],
    // isLoading,
    // isFetching,
  } = useGetClaimsQuery(selectedCustomerId, {
    skip: selectedCustomerId === 0,
  })

  const selectedShipmentDataFull = useMemo(() => {
    return data.filter((it) => modalShipmentIds.includes(it.shipmentId || ''))
  }, [data, modalShipmentIds])

  const resolvedClaims = useClaimsForTab(selectedShipmentDataFull, 'resolved')

  const resolvedTableData = useClaimsWithStatus(resolvedClaims, [
    { key: refundStatusIdkey, value: 6 },
    { key: refundStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 6 },
    { key: reconcileStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 7 },
    { key: refundStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 9 },
    { key: reconcileStatusIdkey, value: 5 },
    { key: refundStatusIdkey, value: 3 },
    { key: reconcileStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 1 },
    { key: refundStatusIdkey, value: 7 },
  ])

  const [resolvedTable] = useTable<RDSCClaim>(resolvedTableData, columns, {
    scrollingDisabled: true,
  })

  return (
    <Modal
      {...{
        title: (
          <span>
            <span className="">{selectedShipmentBusinessName} — Shipment:</span>
            — Submitting Case:
            <span className="text-primary ms-2">{caseId}</span>
          </span>
        ),
        onClose: () => setIsDisplayingConfirmation(false),
        isSmall: true,
      }}
    >
      <div className="float-end d-flex flex-row align-items-center">
        {isDisplayingSuccess ? (
          <Check className="text-success me-2" size={30} />
        ) : null}
        {isDisplayingError ? (
          <span className="text-danger">
            {errorMessage ? <span className="me-2">{errorMessage}</span> : null}
            <X className="me-2" size={30} />
          </span>
        ) : null}
        {request.isLoading ? (
          <SpinnerCircularSplit className="me-2" size={30} />
        ) : null}
        <button
          disabled={modalShipmentIds.length === 0}
          className="btn btn-md btn-success"
          onClick={() => {
            void applyChanges()
          }}
        >
          Submit
        </button>
      </div>
      <h5 className="mb-4">Included SKUs:</h5>
      {table}
      <h5 className="my-4">Excluded SKUs:</h5>
      {resolvedTable}
    </Modal>
  )
}
