import React from 'react'
import { SpinnerDotted } from 'spinners-react'

export default () => (
  <div
    className="container d-flex justify-content-center align-items-center"
    style={{ height: '100%' }}
  >
    <div>
      <SpinnerDotted />
    </div>
  </div>
)
