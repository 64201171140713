import { sumBy } from 'lodash'
import React, { useMemo } from 'react'

import { RDSCStats } from './../../types'
import { toDollarString } from '../../lib/string'

const StatsItem = ({ value, label }) => {
  return (
    <div className="col-lg-3 col-md-4 col-6 p-3 text-center">
      <h3>{value}</h3>
      <span>{label}</span>
    </div>
  )
}

export default function Stats({ data }: { data: RDSCStats[] }) {
  const {
    nNewClaims,
    nPressingNewClaims,
    nRequestedClaims,
    nPressingRequestedClaims,
    nSubmittedClaims,
    valueSubmittedClaims,
    nReimbursedClaims,
    valueReimbursedClaims,
  } = useMemo(() => {
    const nNewClaims = sumBy(data, 'nNewClaims')
    const nPressingNewClaims = sumBy(data, 'nPressingNewClaims')
    const nRequestedClaims = sumBy(data, 'nRequestedClaims')
    const nPressingRequestedClaims = sumBy(data, 'nPressingRequestedClaims')
    const nSubmittedClaims = sumBy(data, 'nSubmittedClaims')
    const valueSubmittedClaims = toDollarString(
      sumBy(data, 'valueSubmittedClaims') * 100
    )
    const nReimbursedClaims = sumBy(data, 'nReimbursedClaims')
    const valueReimbursedClaims = toDollarString(
      sumBy(data, 'valueReimbursedClaims') * 100
    )
    return {
      nNewClaims,
      nPressingNewClaims,
      nRequestedClaims,
      nPressingRequestedClaims,
      nSubmittedClaims,
      valueSubmittedClaims,
      nReimbursedClaims,
      valueReimbursedClaims,
    }
  }, [data])

  return (
    <div className="container bg-light m-3 rounded">
      <div className="row py-3">
        <StatsItem {...{ value: nNewClaims, label: 'Total New Claims' }} />
        <StatsItem
          {...{ value: nRequestedClaims, label: 'Total Requested Claims' }}
        />
        <StatsItem
          {...{ value: nSubmittedClaims, label: 'Total Submitted Claims' }}
        />
        <StatsItem
          {...{ value: nReimbursedClaims, label: 'Total Reimbursed Items' }}
        />
        <StatsItem
          {...{ value: nPressingNewClaims, label: 'Total Pressing New Claims' }}
        />
        <StatsItem
          {...{
            value: nPressingRequestedClaims,
            label: 'Total Pressing Requested Claims',
          }}
        />
        <StatsItem
          {...{
            value: valueSubmittedClaims,
            label: 'Total Submitted Claims Value',
          }}
        />
        <StatsItem
          {...{
            value: valueReimbursedClaims,
            label: 'Total Reimbursed Claims Value',
          }}
        />
      </div>
    </div>
  )
}
