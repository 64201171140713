import React, { useMemo, useState } from 'react'
import { Check, X } from 'react-feather'
import { SpinnerCircularSplit } from 'spinners-react'

import { RDSCClaim } from '../../types'
import columns from '../config/table-columns/submit-confirm'
import { useClaimsForTab } from '../hooks/claims-for-tab'
import { useClaimsWithStatus } from '../hooks/claims-with-status'
import { useGetClaimsQuery } from '../hooks/request/rdsc-claims'
import Modal from '../lib/components/modal'
import useTable from '../lib/hooks/table'
import { RequestStatus } from '../types'

export default function ModalConfirmRequestDocuments({
  selectedCustomerId,
  selectedShipmentsData,
  selectedShipmentIds,
  isDisplayingError,
  isDisplayingSuccess,
  request,
  applyChanges,
  setIsDisplayingConfirmation,
}: {
  selectedCustomerId: number
  selectedShipmentsData: RDSCClaim[]
  selectedShipmentIds: string[]
  isDisplayingError: boolean
  isDisplayingSuccess: boolean
  request: RequestStatus
  applyChanges: () => Promise<void>
  setIsDisplayingConfirmation: (arg0: boolean) => void
}) {
  // Prevent data change on table change after request success
  const [modalShipmentData] = useState<RDSCClaim[]>(selectedShipmentsData)
  const [modalShipmentIds] = useState<string[]>(selectedShipmentIds)

  const { isLoading } = request

  const selectedShipmentDataWithEligibleStatus = useMemo(() => {
    return modalShipmentData.map((it) => {
      return Object.assign({}, it, { status: 'Eligible' })
    })
  }, [modalShipmentData])

  const [table] = useTable<RDSCClaim>(
    selectedShipmentDataWithEligibleStatus,
    columns,
    {
      scrollingDisabled: true,
    }
  )

  const selectedShipmentBusinessName = modalShipmentData.length
    ? modalShipmentData[0].businessName
    : ''

  const { data = [] } = useGetClaimsQuery(selectedCustomerId, {
    skip: selectedCustomerId === 0,
  })

  const selectedShipmentsDataFull = useMemo(() => {
    return data.filter((it) => modalShipmentIds.includes(it.shipmentId || ''))
  }, [data, modalShipmentIds])

  const resolvedClaims = useClaimsForTab(selectedShipmentsDataFull, 'resolved')

  const refundStatusIdkey = 'amzRefundStatusId'
  const reconcileStatusIdkey = 'reconcileStatusId'

  const resolvedTableData = useClaimsWithStatus(resolvedClaims, [
    { key: refundStatusIdkey, value: 6 },
    { key: refundStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 6 },
    { key: reconcileStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 7 },
    { key: refundStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 9 },
    { key: reconcileStatusIdkey, value: 5 },
    { key: refundStatusIdkey, value: 3 },
    { key: reconcileStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 1 },
    { key: refundStatusIdkey, value: 7 },
  ])

  const [resolvedTable] = useTable<RDSCClaim>(resolvedTableData, columns, {
    scrollingDisabled: true,
  })

  return (
    <Modal
      {...{
        title: (
          <span>
            <span className="">
              {selectedShipmentBusinessName} — Requesting Documents:
            </span>
          </span>
        ),
        onClose: () => setIsDisplayingConfirmation(false),
        isSmall: true,
      }}
    >
      <div className="float-end d-flex flex-row align-items-center">
        {isDisplayingSuccess ? (
          <Check className="text-success me-2" size={30} />
        ) : null}
        {isDisplayingError ? (
          <X className="text-danger me-2" size={30} />
        ) : null}
        {isLoading ? <SpinnerCircularSplit className="me-2" size={30} /> : null}
        <button
          disabled={modalShipmentIds.length === 0}
          className="btn btn-md btn-success"
          onClick={() => {
            void applyChanges()
          }}
        >
          Submit
        </button>
      </div>
      <h5 className="mb-4">Included SKUs:</h5>
      {table}
      <h5 className="my-4">Excluded SKUs:</h5>
      {resolvedTable}
    </Modal>
  )
}
