import rdscApi from './../../reducers/request/rdsc'
import getDisabledCustomerAccountsQuery from './disabled-customer-accounts'
import { HttpStatusCode } from '../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    deleteDisabledCustomerAccount: build.mutation<
      HttpStatusCode,
      {
        customerAccountId: number
      }
    >({
      query: ({ customerAccountId }) => ({
        url: 'disabled-customer-accounts',
        method: 'DELETE',
        body: { customerAccountId },
        responseHandler: 'text',
      }),
      async onQueryStarted(
        { customerAccountId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled

          dispatch(
            getDisabledCustomerAccountsQuery.util.updateQueryData(
              'getDisabledCustomerAccounts',
              undefined,
              (draft) => {
                return draft.filter((it: number) => {
                  return it !== customerAccountId
                })
              }
            )
          )
        } catch (e) {
          console.log(e, 'mutate disabled customer accounts')
        }
      },
    }),
  }),
})

export const { useDeleteDisabledCustomerAccountMutation } = extension
export default extension
