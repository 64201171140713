import rdscApi from './../../reducers/request/rdsc'
import { RDSCClaim } from '../../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    getClaims: build.query<RDSCClaim[], number | null | void>({
      query: (customerId: number) => ({
        url: 'rdsc-claims',
        method: 'POST',
        body: { customerId },
      }),
      providesTags: ['Claims'],
    }),
  }),
})

export const { useGetClaimsQuery } = extension
export default extension
