import { clone, groupBy, sumBy } from 'lodash'
import { useMemo } from 'react'

import { RDSCClaim } from './../../types'
import { PRODUCT_STATUSES, REFUND_STATUSES } from './../constants'
import { PriorityEntry, priorityObjectEntryFind } from '../../lib/array'

export type RDSCClaimWithStatus = RDSCClaim & {
  status: string | undefined
}

export const useShipmentsByIdWithStatus = (
  data: RDSCClaim[],
  statusPriorities: PriorityEntry[]
) => {
  const dataByShipment = useMemo(() => {
    const dataByShipmentId = groupBy(data, 'shipmentId')

    const dataByShipment: RDSCClaimWithStatus[] = []

    for (const shipmentId of Object.keys(dataByShipmentId)) {
      const shipmentData = dataByShipmentId[shipmentId]
      const shipment = clone(shipmentData[0]) as RDSCClaimWithStatus

      shipment.amountTotal = sumBy(shipmentData, 'amountTotal')

      const refundStatusIdkey = 'amzRefundStatusId'
      const reconcileStatusIdkey = 'reconcileStatusId'

      const { key, object } = priorityObjectEntryFind<RDSCClaim>(
        shipmentData,
        statusPriorities
      )

      let status: string | undefined

      if (key === refundStatusIdkey && object.amzRefundStatusId) {
        status = REFUND_STATUSES[object.amzRefundStatusId]
      } else if (key === reconcileStatusIdkey && object.reconcileStatusId) {
        status = PRODUCT_STATUSES[object.reconcileStatusId]
      }

      shipment.estimatedTotal = sumBy(shipmentData, 'estimatedTotal')

      shipment.status = status

      dataByShipment.push(shipment)
    }

    return dataByShipment
  }, [data])

  return dataByShipment
}
