import React, { useRef } from 'react'

import useCallbackOnChange from '../lib/hooks/callback-on-change'

type props = {
  isShowing: boolean
  filtersComponent: React.ReactNode
  tableComponent: React.ReactNode
  inputsComponent?: React.ReactNode
  statsComponent?: React.ReactNode
  nClaims: number
}

export default function ClaimsLayout({
  isShowing,
  filtersComponent,
  inputsComponent,
  tableComponent,
  statsComponent,
  nClaims,
}: props) {
  const scrollableRef = useRef<HTMLDivElement>(null)

  useCallbackOnChange(isShowing, () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0
    }
  })

  return (
    <div
      className={`${
        isShowing
          ? 'h-100 d-flex flex-column flex-lg-row overflow-auto'
          : 'd-none'
      }`}
    >
      <div
        className="d-flex flex-column flex-fill overflow-auto"
        style={{
          minHeight: 0 /* required for overflow in flex */,
        }}
      >
        <div className="d-flex flex-row">
          {statsComponent ? <div className="p-2">{statsComponent}</div> : null}
          <div className="ms-auto p-2">{filtersComponent}</div>
        </div>
        <div
          className="border bg-white rounded flex-grow-1"
          style={{ overflowY: 'auto' }}
          ref={scrollableRef}
        >
          {tableComponent}
        </div>
        <div className="d-flex flex-row justify-content-end align-content-center">
          <span>Showing {nClaims} claims</span>
        </div>
      </div>

      {inputsComponent !== undefined ? (
        <div
          className="d-flex flex-row flex-lg-column ps-lg-4 pt-lg-2"
          style={{ minWidth: '300px', overflowY: 'auto' }}
        >
          {inputsComponent}
        </div>
      ) : null}
    </div>
  )
}
