import { MouseEvent, useCallback, useState } from 'react'

export default function useDoubleClick({
  onClick,
  onDoubleClick,
}: {
  onClick(arg0: MouseEvent): void
  onDoubleClick(arg0: MouseEvent): void
}) {
  const [clickTimer, setClickTimer] = useState<object | undefined>(undefined)

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (clickTimer === undefined) {
        // single
        setClickTimer(
          setTimeout(() => {
            onClick(e)
            setClickTimer(undefined)
          }, 200)
        )
      } else {
        // double
        clearTimeout(clickTimer) // TODO type error
        onDoubleClick(e)
        setClickTimer(undefined)
      }
    },
    [clickTimer, setClickTimer, onClick, onDoubleClick]
  )

  const isClicking = clickTimer !== undefined

  return [handleClick, isClicking] as const
}
