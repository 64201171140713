import { isEmpty } from 'lodash'
import React, {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'

import useClipboard from './../hooks/clipboard'
import useDoubleClick from './../hooks/double-click'
import useHighlight from './../hooks/highlight'

export const ClipboardCopyCell = memo(
  ({ value, onClick }: { value: string; onClick(arg0: MouseEvent): void }) => {
    const [copy, isCopied] = useClipboard()
    const [lastHighlight, setLastHighlight] = useState<string | undefined>(
      undefined
    )
    const [highlighted, clearHighlight, isHighlighted] = useHighlight()
    const [clickEvent, setClickEvent] = useState<MouseEvent | undefined>(
      undefined
    )

    const handleOnRowClick = useCallback(
      (e: MouseEvent) => {
        if (e.button === 2) return // right click

        if (isHighlighted) {
          if (value.includes(highlighted)) {
            if (!isEmpty(highlighted) && highlighted === lastHighlight) {
              clearHighlight()
              setLastHighlight(undefined)
            } else {
              setLastHighlight(highlighted)
            }
          }

          return
        }

        onClick(e)
      },
      [
        isHighlighted,
        value,
        highlighted,
        lastHighlight,
        setLastHighlight,
        clearHighlight,
        onClick,
      ]
    )

    useEffect(() => {
      if (clickEvent !== undefined) {
        handleOnRowClick(clickEvent)
        setClickEvent(undefined)
      }
    }, [highlighted, clickEvent, setClickEvent])

    const handleCopy = useCallback(() => {
      clearHighlight()
      copy(String(value))
    }, [value, copy, clearHighlight])

    const [handleClick, isClicking] = useDoubleClick({
      onClick: setClickEvent,
      onDoubleClick: handleCopy,
    })

    const onValueClick = useCallback(
      (e: MouseEvent) => {
        e.stopPropagation()
        handleClick(e)
      },
      [handleClick]
    )

    return (
      <div
        className={`w-100 h-100 ${
          isClicking && !isHighlighted ? 'prevent-select' : ''
        }`}
        style={{ boxSizing: 'border-box', display: 'table' }}
      >
        <span onClick={onValueClick}>{value}</span>
        <span
          className={`${isCopied ? 'ms-2 text-success visible' : 'invisible'}`}
        >
          Copied!
        </span>
      </div>
    )
  }
)
