import { PayloadAction, createAction, createReducer } from '@reduxjs/toolkit'

export type UserState = {
  isAuthorized: boolean
}

function handleAuthorize(state: UserState, action: PayloadAction<boolean>) {
  state.isAuthorized = action.payload
}

const defaultState: UserState = {
  isAuthorized: false,
}

export const authorize = createAction<boolean>('user/authorize')

const reducer = createReducer(defaultState, (builder) => {
  builder.addCase(authorize, handleAuthorize)
})

export default reducer
