import rdscApi from './../../reducers/request/rdsc'
import { RDSCStats } from '../../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    getStats: build.query<RDSCStats[], number | void>({
      query: (customerId) => ({
        url: 'rdsc-stats',
        method: 'POST',
        body: { customerId },
      }),
      providesTags: ['Stats'],
    }),
  }),
})

export const { useGetStatsQuery } = extension
export default extension
