import { uniq } from 'lodash'
import { useMemo } from 'react'

import { RDSCClaim } from '../../types'
import { TabId } from '../config/claim-tabs'
import { tabStatusesLookup } from '../config/tab-statuses'

export const useStatusIdsForTab = (data: RDSCClaim[], tab: TabId) => {
  const statusIds = useMemo(() => {
    let reconcileStatusIds: number[] = []
    let refundStatusIds: number[] = []

    for (const it of data) {
      if (
        it.reconcileStatusId &&
        tabStatusesLookup[tab][1].includes(it.reconcileStatusId)
      ) {
        reconcileStatusIds = uniq([it.reconcileStatusId, ...reconcileStatusIds])
      }

      if (
        it.amzRefundStatusId &&
        tabStatusesLookup[tab][0].includes(it.amzRefundStatusId)
      ) {
        refundStatusIds = uniq([it.amzRefundStatusId, ...refundStatusIds])
      }
    }

    return { refundStatusIds, reconcileStatusIds }
  }, [data])

  return statusIds
}
