import { sum } from 'lodash'
import { useMemo } from 'react'

import { RDSCClaim } from '../../types'

export const useReimbursementStats = (claims: RDSCClaim[]) => {
  const nReimbursableClaims = useMemo(() => {
    const claimQuantities = claims.map((claim) =>
      claim.amzRefundStatusId === 6
        ? Number(claim.quantityReimbursedTotal || 0)
        : Number(claim.nDiscrepancy || 0)
    )

    const nReimbursableClaims = Math.abs(sum(claimQuantities))

    const num = nReimbursableClaims.toLocaleString()
    return num
  }, [claims])

  const reimbursableValue = useMemo(() => {
    const claimAmounts = claims.map((claim) =>
      claim.amzRefundStatusId === 6
        ? claim.amountTotal
        : Number(claim.estimatedTotal || 0)
    )

    const sumTotal = sum(claimAmounts)

    const value = sumTotal.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return value
  }, [claims])

  return {
    nReimbursableClaims,
    reimbursableValue,
  }
}
