import React from 'react'

const Tab = ({
  onClick,
  id,
  title,
  active,
}: {
  onClick: (id: 'new' | 'requested' | 'submitted' | 'resolved') => void
  id: 'new' | 'requested' | 'submitted' | 'resolved'
  title: string
  active: boolean
}) => {
  return (
    <li
      className="nav-item"
      onClick={() => onClick(id)}
      style={{ marginBottom: '-1px', zIndex: 1 }}
    >
      <a
        className={`nav-link ${active ? 'active bg-light' : ''}`}
        aria-current="page"
        href="#"
        style={{ borderBottom: '0' }}
      >
        {title}
      </a>
    </li>
  )
}

export default function Tabs({
  onTabSelect,
  selectedTab,
  tabs,
}: {
  onTabSelect: (
    tab: 'new' | 'requested' | 'submitted' | 'resolved' | undefined
  ) => void
  selectedTab: 'new' | 'requested' | 'submitted' | 'resolved' | undefined
  tabs: { id: 'new' | 'requested' | 'submitted' | 'resolved'; title: string }[]
}) {
  return (
    <div className="ms-3">
      <ul className="nav nav-tabs" style={{ borderBottom: 'none' }}>
        {tabs.map(({ id, title }, i) => {
          return (
            <Tab
              {...{
                id,
                title,
                active: selectedTab === id,
                onClick: onTabSelect,
                key: i,
              }}
            />
          )
        })}
      </ul>
    </div>
  )
}
