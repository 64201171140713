import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useAddDisabledCustomerAccountMutation } from './request/add-disabled-customer-account'
import { useDeleteDisabledCustomerAccountMutation } from './request/delete-disabled-customer-account'
import { useGetDisabledCustomerAccountsQuery } from './request/disabled-customer-accounts'
import { State } from '../types'

export default function useDisabledCustomerAccounts({
  customerAccounts,
}: {
  customerAccounts: { customerAccountId: number; name: string }[]
}) {
  const isAuthorized = useSelector((state: State) => state.user.isAuthorized)

  const { data: disabledCustomerAccounts = [] } =
    useGetDisabledCustomerAccountsQuery(undefined, {
      skip: !isAuthorized,
    })

  const [
    fetchAddDisabledCustomerAccount,
    // addRpmAccountCustomerAccountMappingsRequest,
  ] = useAddDisabledCustomerAccountMutation()

  const [
    fetchDeleteDisabledCustomerAccount,
    // deleteRpmAccountCustomerAccountMappingsRequest,
  ] = useDeleteDisabledCustomerAccountMutation()

  const nonDisabledCustomerAccounts = useMemo(() => {
    return customerAccounts.filter(
      (account) => !disabledCustomerAccounts.includes(account.customerAccountId)
    )
  }, [customerAccounts, disabledCustomerAccounts])

  const addDisabledCustomerAccount = useCallback(
    (customerAccountId: number) => {
      void fetchAddDisabledCustomerAccount({
        customerAccountId,
      })
    },
    [fetchAddDisabledCustomerAccount]
  )

  const removeDisabledCustomerAccount = useCallback(
    (customerAccountId: number) => {
      if (customerAccountId) {
        void fetchDeleteDisabledCustomerAccount({
          customerAccountId,
        })
      }
    },
    [fetchDeleteDisabledCustomerAccount]
  )

  return {
    disabledCustomerAccounts,
    nonDisabledCustomerAccounts,
    addDisabledCustomerAccount,
    removeDisabledCustomerAccount,
    // isLoading,
    // isFetching,
  }
}
