import React from 'react'

import { toDollarString } from '../../../lib/string'
import { ClipboardCopyCell } from '../../lib/components/clipboard-copy-cell'
import { Column } from '../../lib/hooks/table'

export default [
  {
    id: 'fnsku',
    header: 'FNSKU',
    accessorKey: 'fnsku',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'sku',
    header: 'SKU',
    accessorKey: 'sku',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'productName',
    header: 'Product Name',
    accessorKey: 'productName',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'estimatedTotal',
    header: 'Est. Value',
    accessorKey: 'estimatedTotal',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={toDollarString((obj.value as number) * 100)}
      />
    ),
  },
  {
    id: 'expected',
    header: 'Expected',
    accessorKey: 'nExpected',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={(obj.value as string) || 'N/A'}
      />
    ),
  },
  {
    id: 'discrepancy',
    header: 'Discrepancy',
    accessorKey: 'nDiscrepancy',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={(obj.value as string) || 'N/A'}
      />
    ),
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={String(obj.value ?? 'N/A')}
      />
    ),
  },
] as Column[]
