import { useCallback, useEffect, useState } from 'react'

export default (): [string, () => void, boolean] => {
  const [highlighted, setHighlighted] = useState<string>('')
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false)

  const handleSelectionChange = useCallback(() => {
    const selection = window.getSelection() || ({} as Selection)
    const newSelectedText = selection?.toString() || ''

    setHighlighted(newSelectedText)
    setIsHighlighted(selection.anchorOffset !== selection.focusOffset)
  }, [highlighted, setHighlighted, setIsHighlighted])

  useEffect(() => {
    document.addEventListener('mouseup', handleSelectionChange)

    return () => {
      document.removeEventListener('mouseup', handleSelectionChange)
    }
  }, [handleSelectionChange])

  const clearHighlight = useCallback(() => {
    const selection = window.getSelection()
    selection?.removeAllRanges()
    setHighlighted('')
    setIsHighlighted(false)
  }, [setHighlighted, setIsHighlighted])

  return [highlighted, clearHighlight, isHighlighted]
}
