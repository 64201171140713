import React from 'react'

import { RDSCClaim } from './../../types'
import SkusLayout from './skus-layout'
import SkusRequestedInputs from './skus-requested-inputs'
import useSKUsTable from '../hooks/component/skus-table'

export default function RequestedSKUs({
  data,
  selectedCustomerId,
}: {
  data: RDSCClaim[]
  selectedCustomerId: number
}) {
  const {
    table,
    selectedShipmentIds,
    selectedShipmentsData,
    unselectRows,
    selectedRowsData,
  } = useSKUsTable({ data, tabId: 'requested' })

  return (
    <SkusLayout
      {...{
        tableComponent: table,
        inputsComponent: (
          <SkusRequestedInputs
            {...{
              selectedShipmentsData,
              selectedShipmentIds,
              selectedCustomerId,
              selectedRowsData,
              unselectRows,
            }}
          />
        ),
      }}
    />
  )
}
