import { addDays, format, parseISO } from 'date-fns'
import moment from 'moment'

export const formatUnix = (unix: number | undefined | null) => {
  if (unix === null || unix === undefined) return 'N/A'
  const dateString = moment.unix(unix).format('YYYY-MM-DD')
  return dateString
}

export function generateDateArray(startDate: string) {
  const currentDate = new Date()
  let currentDateIterator = parseISO(startDate)

  const dateArray = []

  while (currentDateIterator <= currentDate) {
    dateArray.push(format(currentDateIterator, 'yyyy-MM-dd'))
    currentDateIterator = addDays(currentDateIterator, 1)
  }

  return dateArray
}
