import React from 'react'

type props = {
  tableComponent: React.ReactNode
  inputsComponent?: React.ReactNode
}

export default function SkusLayout({ inputsComponent, tableComponent }: props) {
  return (
    <div className="h-100 d-flex bg-light rounded p-3 flex-column flex-lg-row overflow-auto">
      <div
        className="d-flex flex-column flex-fill overflow-auto"
        style={{
          minHeight: 0 /* required for overflow in flex */,
        }}
      >
        <div
          className="border bg-white rounded flex-grow-1"
          style={{ overflowY: 'auto' }}
        >
          {tableComponent}
        </div>
      </div>

      {inputsComponent !== undefined ? (
        <div
          className="d-flex flex-row flex-lg-column flex-wrap ps-lg-4 pt-lg-2"
          style={{ minWidth: '300px' }}
        >
          {inputsComponent}
        </div>
      ) : null}
    </div>
  )
}
