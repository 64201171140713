import React from 'react'
import { Search, X } from 'react-feather'

import ClaimsLayout from './claims-layout'
import InputsComponent from './claims-new-inputs'
import useClaimsTable from '../hooks/component/claims-table'

export default function NewClaims({
  isShowing,
  selectShipmentId,
  selectedCustomerId,
}: {
  isShowing: boolean
  selectShipmentId: (id: string) => void
  selectedCustomerId: number
}) {
  const {
    isModalShowing,
    setIsModalShowing,

    isLoading,
    isFetching,

    selectedShipmentIds,
    selectedShipmentsData,
    nClaimsByShipmentId,

    nReimbursableClaims,
    reimbursableValue,

    setSearchQuery,

    storeNames,
    selectedStoreNames,
    removeStoreName,
    addStoreName,

    table,
    unselectRows,
  } = useClaimsTable({ selectedCustomerId, selectShipmentId, tabId: 'new' })

  return (
    <ClaimsLayout
      {...{
        isShowing: isShowing && !isLoading && !isFetching,
        isModalShowing,
        setIsModalShowing,
        selectedShipmentIds,
        selectedShipmentsData,
        nClaims: nClaimsByShipmentId,
        statsComponent: (
          <div className="d-flex flex-row flex-wrap">
            <div className="me-4">
              <span>Quantity:</span>
              <span className="text-success fw-bold ms-2">
                {nReimbursableClaims}
              </span>
            </div>
            <div>
              <span>Value:</span>
              <span className="text-success fw-bold ms-2">
                {reimbursableValue}
              </span>
            </div>
          </div>
        ),
        filtersComponent: (
          <div className="float-end">
            <div className="d-flex flex-row">
              {selectedStoreNames.map((storeName) => {
                return (
                  <div
                    className="me-3 btn btn-sm btn-secondary"
                    key={storeName}
                    onClick={() => {
                      removeStoreName(storeName)
                    }}
                  >
                    {storeName} <X {...{ size: 15 }} />
                  </div>
                )
              })}

              <div className="form-group">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control"
                    type="text"
                    defaultValue=""
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
              </div>

              <div className="btn-group ms-2" role="group">
                <div className="btn-group btn-group-sm">
                  <button
                    className="btn btn-light btn-outline-dark border-custom dropdown-toggle px-3"
                    type="button"
                    id="actionType"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Store
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="statusType">
                    {storeNames.map((storeName) => {
                      if (!storeName) return null

                      return (
                        <li
                          key={storeName}
                          onClick={() => addStoreName(storeName)}
                        >
                          <a className="dropdown-item" href="#">
                            {storeName}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ),
        tableComponent: table,
        inputsComponent: (
          <InputsComponent
            {...{
              selectedShipmentsData,
              unselectRows,
              selectedShipmentIds,
              selectedCustomerId,
              selectShipmentId,
            }}
          />
        ),
      }}
    />
  )
}
