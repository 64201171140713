import React, { MouseEvent } from 'react'

import { formatUnix } from '../../../lib/date'
import { toDollarString } from '../../../lib/string'
import { ClipboardCopyCell } from '../../lib/components/clipboard-copy-cell'
import { Column } from '../../lib/hooks/table'

export default [
  {
    id: 'shipmentId',
    header: 'Shipment ID',
    accessorKey: 'shipmentId',
    sort: 'string',
    cell: (obj) => {
      return (
        <ClipboardCopyCell
          onClick={obj.onClick}
          value={(obj.value as string) || 'N/A'}
        />
      )
    },
  },
  {
    id: 'businessName',
    header: 'Store Front Name',
    accessorKey: 'businessName',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell onClick={obj.onClick} value={obj.value as string} />
    ),
  },
  {
    id: 'deadline',
    header: 'Deadline',
    accessorKey: 'deadline',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={formatUnix(obj.value as number) ?? 'N/A'}
      />
    ),
  },
  {
    id: 'shipDate',
    header: 'Ship Date',
    accessorKey: 'shipmentCreatedDate',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={formatUnix(obj.value as number) ?? 'N/A'}
      />
    ),
  },
  {
    id: 'estimatedTotal',
    header: 'Est. Value',
    accessorKey: 'estimatedTotal',
    sort: 'number',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={toDollarString((obj.value as number) * 100)}
      />
    ),
  },
  {
    id: 'status',
    header: 'Status',
    accessorKey: 'status',
    sort: 'string',
    cell: (obj) => (
      <ClipboardCopyCell
        onClick={obj.onClick}
        value={String(obj.value ?? 'N/A')}
      />
    ),
  },
  {
    header: 'View SKUs',
    id: 'open',
    accessorKey: '_id',
    cell: (obj) => (
      <button
        className="btn btn-sm btn-primary"
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          return obj.openFn ? obj.openFn(obj.value) : undefined
        }}
      >
        Open
      </button>
    ),
  },
] as Column[]
