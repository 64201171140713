import rdscApi from './../../reducers/request/rdsc'
import { RpmAccount } from '../../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    getRpmAccounts: build.query<RpmAccount[], number | void>({
      query: () => ({
        url: 'rpm-accounts',
        method: 'GET',
      }),
      providesTags: ['Accounts'],
    }),
  }),
})

export const { useGetRpmAccountsQuery } = extension
export default extension
