import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import Overview from './components/overview'
import { captureError } from './lib/capture-error'
import { store } from './store'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

window.onerror = function (msg, url, ln, column, errorObj) {
  if (errorObj !== undefined) {
    void captureError(errorObj)
  }
}

const root = document.getElementById('root')
if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <Provider store={store}>
        <Overview />
      </Provider>
    </React.StrictMode>
  )
} else {
  console.error('no root element')
}
