import equal from 'deep-equal'
import { pickBy } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useUpdateRefundsDetailsMutation } from './request/update-refunds-details'
import { RDSCClaim } from '../../types'
import { RefundDetails } from '../../types/query'

export default function useUpdateRefundsDetails({
  selectedRowsData,
}: {
  selectedRowsData: RDSCClaim[]
}) {
  const [details, setDetails] = useState<RefundDetails>({})

  const applyDetails = (details: RefundDetails) => {
    const newDetails = pickBy(details, (val) => val !== undefined)
    setDetails(newDetails)
  }

  const [fetchUpdateRefundsDetails, request] = useUpdateRefundsDetailsMutation()

  const prevSelectedRowsData = useRef<RDSCClaim[]>(selectedRowsData)
  useEffect(() => {
    if (equal(selectedRowsData, prevSelectedRowsData.current)) {
      setDetails({})
      prevSelectedRowsData.current = selectedRowsData
    }
  }, [selectedRowsData, setDetails])

  const applyRefundsDetails = useCallback(async () => {
    if (selectedRowsData.length === 0) return

    const amzRefundIds = selectedRowsData.map((it) => it.amzRefundId)

    const customerId = selectedRowsData[0].customerId

    await fetchUpdateRefundsDetails({
      details,
      amzRefundIds,
      customerId,
    })
  }, [details, selectedRowsData])

  return {
    applyRefundsDetails,
    setDetails: applyDetails,
    details,
    request,
  }
}
