import { useClaimsWithStatus } from '../claims-with-status'
import { useTableSelectedShipments } from '../table-selected-shipment'
import { RDSCClaim } from '../../../types'
import columnsNew from '../../config/table-columns/skus-new'
import columnsRequested from '../../config/table-columns/skus-requested'
import columnsResolved from '../../config/table-columns/skus-resolved'
import columnsSubmitted from '../../config/table-columns/skus-submitted'
import useTable from '../../lib/hooks/table'

const refundStatusIdkey = 'amzRefundStatusId'
const reconcileStatusIdkey = 'reconcileStatusId'

const columnsLookup = {
  new: columnsNew,
  requested: columnsRequested,
  submitted: columnsSubmitted,
  resolved: columnsResolved,
}

const statusPrioritiesLookup = {
  new: [],
  requested: [
    { key: reconcileStatusIdkey, value: 3 },
    { key: reconcileStatusIdkey, value: 2 },
    { key: reconcileStatusIdkey, value: 1 },
  ],
  submitted: [
    { key: reconcileStatusIdkey, value: 2 },
    { key: refundStatusIdkey, value: 2 },
  ],
  resolved: [
    { key: refundStatusIdkey, value: 6 },
    { key: refundStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 6 },
    { key: reconcileStatusIdkey, value: 4 },
    { key: reconcileStatusIdkey, value: 7 },
    { key: refundStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 9 },
    { key: reconcileStatusIdkey, value: 5 },
    { key: refundStatusIdkey, value: 3 },
    { key: reconcileStatusIdkey, value: 8 },
    { key: refundStatusIdkey, value: 1 },
    { key: refundStatusIdkey, value: 7 },
  ],
}

export default function useSKUs({
  data,
  tabId,
}: {
  data: RDSCClaim[]
  tabId: 'new' | 'requested' | 'submitted' | 'resolved'
}) {
  const claimsWithStatus = useClaimsWithStatus(
    data,
    statusPrioritiesLookup[tabId]
  )

  const [table, { selectedRowsData, unselectRows }] = useTable(
    claimsWithStatus,
    columnsLookup[tabId],
    {
      ...(tabId !== 'resolved'
        ? {
            select: 'multiple',
          }
        : {}),
    }
  )

  const { selectedShipmentIds, selectedShipmentsData } =
    useTableSelectedShipments({
      selectedRowsData,
      claims: data,
    })

  return {
    table,
    selectedShipmentsData,
    selectedShipmentIds,
    selectedRowsData,
    unselectRows,
  }
}
