import { isEmpty } from 'lodash'
import React from 'react'
import { Copy } from 'react-feather'

import useClipboard from './../lib/hooks/clipboard'

const ClickCopyElement = ({ value }: { value: string }) => {
  const [copy, isCopied] = useClipboard()

  return (
    <span className="input-group-text" onClick={() => copy(value)}>
      <Copy className={`${isCopied ? 'text-success' : ''}`} />
      <span
        className={isCopied ? 'ms-3 text-success prevent-select' : 'd-none'}
      >
        Copied!
      </span>
    </span>
  )
}

export default function CaseManagerEmailInfo({
  username,
  password,
}: {
  username?: string | null
  password?: string | null
}) {
  return (
    <div>
      {!isEmpty(username) && !isEmpty(password) ? (
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            style={{ maxWidth: '240px' }}
            value={username || ''}
            readOnly={true}
            aria-label="Username"
          />
          <ClickCopyElement value={username || ''} />
          <input
            type="password"
            className="form-control"
            style={{ maxWidth: '180px' }}
            value="casemanager@refundpros.ca"
            readOnly={true}
            aria-label="Password"
          />
          <ClickCopyElement value={password || ''} />
        </div>
      ) : null}
    </div>
  )
}
