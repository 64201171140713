import rdscApi from './../../reducers/request/rdsc'
import getRpmAccountCustomerAccountMappingsQuery from './rpm-account-customer-account-mappings'
import { RpmAccountCustomerAccountMapping } from '../../../types'
import { HttpStatusCode } from '../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    deleteRpmAccountCustomerAccountMapping: build.mutation<
      HttpStatusCode,
      {
        rpmAccountId: number
        customerAccountId: number
      }
    >({
      query: ({ rpmAccountId, customerAccountId }) => ({
        url: 'rpm-account-customer-account-mapping',
        method: 'DELETE',
        body: { rpmAccountId, customerAccountId },
        responseHandler: 'text',
      }),
      async onQueryStarted(
        { rpmAccountId, customerAccountId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled

          dispatch(
            getRpmAccountCustomerAccountMappingsQuery.util.updateQueryData(
              'getRpmAccountCustomerAccountMappings',
              undefined,
              (draft) => {
                return draft.filter((it: RpmAccountCustomerAccountMapping) => {
                  return !(
                    it.rpmAccountId === rpmAccountId &&
                    it.customerAccountId === customerAccountId
                  )
                })
              }
            )
          )
        } catch (e) {
          console.log(e, 'mutate customer claims')
        }
      },
    }),
  }),
})

export const { useDeleteRpmAccountCustomerAccountMappingMutation } = extension
export default extension
