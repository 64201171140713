import { uniq } from 'lodash'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import useDisabledCustomerAccounts from '../../hooks/disabled-customer-accounts'
import { State } from '../../types'
import usePersons from '../persons'
import { useGetStatsQuery } from '../request/rdsc-stats'
import { useGetRpmAccountCustomerAccountMappingsQuery } from '../request/rpm-account-customer-account-mappings'
import { useSelectPersonsClaims } from '../select-person-claims'

export default function useOverviewTable() {
  const isAuthorized = useSelector((state: State) => state.user.isAuthorized)

  const { data: rdscStats = [], isLoading: rdscStatsIsLoading } =
    useGetStatsQuery(undefined, {
      skip: !isAuthorized,
    })

  const customerAccounts = useMemo(() => {
    return rdscStats.map((it) => ({
      customerAccountId: it.customerId,
      name: it.businessName,
    }))
  }, [rdscStats])

  const {
    disabledCustomerAccounts,
    nonDisabledCustomerAccounts,
    addDisabledCustomerAccount,
    removeDisabledCustomerAccount,
  } = useDisabledCustomerAccounts({ customerAccounts })

  const rdscStatsFiltered = useMemo(() => {
    return rdscStats.filter(
      (it) => !disabledCustomerAccounts.includes(it.customerId)
    )
  }, [rdscStats, disabledCustomerAccounts])

  const {
    data: rpmAccountCustomerAccountMappings = [],
    // isLoading,
    // isFetching,
  } = useGetRpmAccountCustomerAccountMappingsQuery(undefined, {
    skip: !isAuthorized,
  })

  const { persons } = usePersons()

  const tableDataWithPersons = useMemo(() => {
    return (rdscStatsFiltered || []).map((it) => {
      const mappings = rpmAccountCustomerAccountMappings.filter(
        (mapping) => mapping.customerAccountId === it.customerId
      )

      const mappedPersons = mappings.map((mapping) => {
        const person = persons.find(
          (person) => person.accountId === mapping.rpmAccountId
        )

        return person ? person.userName : ''
      })

      return Object.assign({}, it, { persons: uniq(mappedPersons).join(', ') })
    })
  }, [rdscStatsFiltered, rpmAccountCustomerAccountMappings, persons])

  const {
    claims: selectedPersonsClaims,
    selectedPersons,
    addPerson,
    removePerson,
    persons: claimsPersons,
  } = useSelectPersonsClaims(tableDataWithPersons)

  const [searchQuery, setBouncingSearchQuery] = useState('')

  const setSearchQuery = useDebouncedCallback((value: string) => {
    setBouncingSearchQuery(value)
  }, 300)

  const searchedClaims = useMemo(() => {
    return selectedPersonsClaims.filter((it) => {
      const values = Object.values(it).join('').toLowerCase()
      const includes = values.includes(searchQuery.toLowerCase())
      return includes
    })
  }, [selectedPersonsClaims, searchQuery])

  return {
    rdscStats: rdscStatsFiltered,
    rdscStatsIsLoading,

    tableData: searchedClaims,

    setSearchQuery,

    claimsPersons,
    selectedPersons,
    removePerson,
    addPerson,

    disabledCustomerAccounts,
    nonDisabledCustomerAccounts,
    addDisabledCustomerAccount,
    removeDisabledCustomerAccount,
  }
}
