export const captureError = (error: Error | string) => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  const msg: string = error.message ? error.message : error

  return fetch('/api/frontend-error', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      error: msg,
      ...(typeof error === 'string' ? {} : { stack: error.stack }),
    }),
  })
}
