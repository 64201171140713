import React from 'react'

import ClaimsModal from './modal-claims'
import SKUsModal from './modal-skus'
import OverviewFilters from './overview-filters'
import Stats from './overview-stats'
import ModalAuthorize from '../components/modal-authorize'
import useOverview from '../hooks/component/overview'
import SpinnerContainer from '../lib/components/spinner-container'

function Overview() {
  const {
    rdscStatsData,
    rdscStatsIsLoading,

    selectedCustomerStats,

    claimsPersons,
    selectedPersons,
    removePerson,
    addPerson,

    selectedCustomerId,
    selectCustomerId,

    selectedShipmentId,
    selectShipmentId,

    setSearchQuery,

    selectedTab,
    selectTab,

    disabledCustomerAccounts,
    nonDisabledCustomerAccounts,
    addDisabledCustomerAccount,
    removeDisabledCustomerAccount,

    table,
  } = useOverview()

  return (
    <div className="d-flex flex-column h-100">
      <ModalAuthorize />

      <div className="w-100">
        <div className="container">
          <Stats {...{ data: rdscStatsData }} />
        </div>
      </div>

      <OverviewFilters
        {...{
          setSearchQuery,
          claimsPersons,
          selectedPersons,
          removePerson,
          addPerson,
        }}
      />

      <div className="flex-grow-1 border border-custom rounded overflow-auto h-100">
        <div className={selectedCustomerId !== 0 ? '' : 'd-none'}>
          <ClaimsModal
            {...{
              selectedCustomerStats,
              selectCustomerId,
              selectedTab,
              selectTab,
              selectedCustomerId,
              selectShipmentId,
            }}
          />
        </div>
        <div className={selectedShipmentId !== '' ? '' : 'd-none'}>
          <SKUsModal
            {...{
              selectCustomerId,
              selectedTab,
              selectTab,
              selectedCustomerId,
              selectShipmentId,
              selectedShipmentId,
            }}
          />
        </div>

        {!rdscStatsIsLoading ? table : <SpinnerContainer />}

        <div className="d-flex flex-row justify-content-end mb-3">
          {disabledCustomerAccounts.map((disabledCustomerAccountId, i) => {
            return (
              <button
                type="button"
                className="me-2 px-2 border-custom btn btn-sm btn-light text-dark"
                onClick={() =>
                  removeDisabledCustomerAccount(disabledCustomerAccountId)
                }
                key={i}
              >
                {disabledCustomerAccountId}
              </button>
            )
          })}
          <div className="dropup me-3">
            <button
              className="btn btn-sm btn-light btn-outline-dark border-custom dropdown-toggle"
              type="button"
              id="disabled-customer-accounts"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Hide Customer Account
            </button>
            <ul
              className="dropdown-menu"
              style={{ maxHeight: '300px', overflowY: 'scroll' }}
              aria-labelledby="disabled-customer-accounts"
            >
              {nonDisabledCustomerAccounts.map(
                ({ customerAccountId, name }, i) => {
                  return (
                    <li key={i}>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          addDisabledCustomerAccount(customerAccountId)
                        }
                      >
                        {`${customerAccountId} - ${name}`}
                      </button>
                    </li>
                  )
                }
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
