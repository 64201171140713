import { useOutsideClick } from '@chakra-ui/react-use-outside-click'
import React, { useRef, useState } from 'react'
import { XCircle } from 'react-feather'

import useDatePicker from './../hooks/component/date-picker'

type props = {
  onChange: (
    state: [Date | undefined, Date | undefined]
  ) => void | Promise<void>
  minDate: Date
  maxDate: Date
}

export default function DatePicker({
  onChange,
  minDate = new Date(),
  maxDate = new Date(),
}: props) {
  const ref = useRef<HTMLDivElement>(null)
  const [isHoveringCloseButton, setIsHoveringCloseButton] =
    useState<boolean>(false)

  const {
    onResetDateRange,
    hasChanged,
    setIsDatePickerShowing,
    isDatePickerShowing,
    dateRangeString,
    component,
  } = useDatePicker({ onChange, minDate, maxDate })

  useOutsideClick({
    ref,
    handler: () => setIsDatePickerShowing(false),
  })

  return (
    <div className="btn-group btn-group-sm dropdown" ref={ref}>
      <button
        className="btn btn-light btn-outline-dark border-custom dropdown-toggle px-3"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => {
          setIsDatePickerShowing(!isDatePickerShowing)
        }}
      >
        <span>
          <span>{dateRangeString}</span>
          <span
            style={{
              display: hasChanged ? '' : 'none',
            }}
          >
            {' '}
            <XCircle
              onMouseEnter={() => setIsHoveringCloseButton(true)}
              onMouseLeave={() => setIsHoveringCloseButton(false)}
              className={isHoveringCloseButton ? 'text-danger' : ''}
              onClick={onResetDateRange}
            />
          </span>
        </span>
      </button>
      <div
        className={`dropdown-menu pe-2 ${isDatePickerShowing ? 'show' : ''}`}
        aria-labelledby="dropdownMenuButton"
        style={{ marginLeft: '-300px', marginTop: '50px' }}
      >
        {component}
      </div>
    </div>
  )
}
