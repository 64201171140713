import React from 'react'

type props = {
  children: React.ReactElement[] | React.ReactElement
  className?: string
  style?: React.CSSProperties
}

export default ({ children, className, style = {} }: props) => (
  <div
    className={[
      `container d-flex justify-content-center align-items-center`,
      className,
    ].join(' ')}
    style={{ height: '100%', ...style }}
  >
    <div>{children}</div>
  </div>
)
