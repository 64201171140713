import { isEmpty, uniqBy } from 'lodash'
import React from 'react'

import { RDSCClaim } from '../../types'

export default function InputsComponent({
  selectedShipmentsData,
}: {
  selectedShipmentsData: RDSCClaim[]
}) {
  const existingNotes = uniqBy(
    selectedShipmentsData.filter((it) => !isEmpty(it.caseManagerNotes)),
    'shipmentId'
  )

  return (
    <>
      <div className="me-3 me-lg-0 mt-4 mt-lg-3">
        <label htmlFor="new-note" className="form-label">
          Notes:
        </label>
        {existingNotes.map((it, i) => {
          return (
            <div key={i} className="card mt-2">
              <div className="card-body">
                <span className="card-title text-secondary">
                  {it.shipmentId}
                </span>
                <p className="card-text">{it.caseManagerNotes}</p>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
