import { subDays, subMonths } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import {
  DateRangePicker,
  RangeKeyDict,
  createStaticRanges,
} from 'react-date-range'

type props = {
  onChange: (
    state: [Date | undefined, Date | undefined]
  ) => void | Promise<void>
  minDate: Date
  maxDate: Date
}

export default function useDatePicker({
  onChange,
  minDate = new Date(),
  maxDate = new Date(),
}: props) {
  const [isDatePickerShowing, setIsDatePickerShowing] = useState(false)
  const [dateRangePicker, setDateRangePicker] = useState<
    RangeKeyDict | undefined
  >()
  const [hasChanged, setHasChanged] = useState(false)

  const onChangeDateRange = useCallback(
    (value: RangeKeyDict) => {
      setDateRangePicker(value)
      setHasChanged(true)
      void onChange([value.selection.startDate, value.selection.endDate])
    },
    [setDateRangePicker, setHasChanged, onChange]
  )

  const onResetDateRange = useCallback(
    (e: Event) => {
      e.stopPropagation()
      setHasChanged(false)
      setDateRangePicker(undefined)
      void onChange([undefined, undefined])
    },
    [setDateRangePicker, setHasChanged, onChange, minDate, maxDate]
  )

  const today = useMemo(() => new Date(), [])
  const NineMonthsAgo = useMemo(() => subMonths(today, 9), [today])

  const defaultStartDate = useMemo(
    () =>
      NineMonthsAgo.getTime() > minDate.getTime() ? NineMonthsAgo : minDate,
    [NineMonthsAgo, minDate]
  )

  const component = useMemo(() => {
    return (
      <DateRangePicker
        displayMode={'dateRange'}
        ranges={[
          ...(dateRangePicker !== undefined
            ? [{ ...dateRangePicker.selection, key: 'selection' }]
            : [
                {
                  startDate: defaultStartDate,
                  endDate: maxDate,
                  key: 'selection',
                },
              ]),
        ]}
        staticRanges={createStaticRanges([
          {
            label: 'Last Week',
            range: () => ({
              startDate: subDays(today, 6),
              endDate: today,
            }),
          },
          {
            label: 'Last Month',
            range: () => ({
              startDate: subMonths(today, 1),
              endDate: today,
            }),
          },
          {
            label: 'Last 9 Months',
            range: () => ({
              startDate: NineMonthsAgo,
              endDate: today,
            }),
          },
          {
            label: 'Last 12 Months',
            range: () => ({
              startDate: subMonths(new Date(), 12),
              endDate: today,
            }),
          },
          {
            label: 'All',
            range: () => ({
              startDate: minDate,
              endDate: today,
            }),
          },
        ])}
        calendarFocus={'backwards'}
        initialFocusedRange={[3, 1]}
        preventSnapRefocus={true}
        // focusedRange={[0, 1]}
        {...{ minDate, maxDate }}
        onChange={onChangeDateRange}
      />
    )
  }, [
    onChangeDateRange,
    minDate,
    maxDate,
    dateRangePicker,
    today,
    NineMonthsAgo,
    defaultStartDate,
  ])

  const dateRangeString = useMemo(() => {
    return dateRangePicker !== undefined &&
      dateRangePicker.selection !== undefined
      ? dateRangePicker.selection.startDate?.toLocaleDateString('en-US') +
          ' - ' +
          dateRangePicker.selection.endDate?.toLocaleDateString('en-US')
      : defaultStartDate.toLocaleDateString('en-US') +
          ' - ' +
          maxDate.toLocaleDateString('en-US')
  }, [dateRangePicker, minDate, maxDate])

  return {
    onResetDateRange,
    onChangeDateRange,
    hasChanged,
    setIsDatePickerShowing,
    isDatePickerShowing,
    dateRangePicker,
    dateRangeString,
    setDateRangePicker,
    component,
  }
}
