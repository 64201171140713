export type TabId = 'new' | 'requested' | 'submitted' | 'resolved'

export type Tab = {
  id: TabId
  title: string
}

const claimTabs: Tab[] = [
  {
    id: 'new',
    title: 'New',
  },
  {
    id: 'requested',
    title: 'Documents Requested',
  },
  {
    id: 'submitted',
    title: 'Submitted',
  },
  {
    id: 'resolved',
    title: 'Resolved',
  },
]

export default claimTabs
