export const PROVINCES = {
  1: ['AB', 'Alberta'],
  2: ['BC', 'British Columbia'],
  3: ['MB', 'Manitoba'],
  4: ['NB', 'New Brunswick'],
  5: ['NL', 'Newfoundland and Labrador'],
  6: ['NT', 'Northwest Territories'],
  7: ['NS', 'Nova Scotia'],
  8: ['NU', 'Nunavut'],
  9: ['ON', 'Ontario'],
  10: ['PE', 'Prince Edward Island'],
  11: ['QC', 'Quebec'],
  12: ['SK', 'Saskatchewan'],
  13: ['YT', 'Yukon'],
  14: ['NA', 'Not Applicable'],
}

export const PRODUCT_STATUSES: { [key: number]: string } = {
  1: 'Documents Requested',
  2: 'Needs Additional Documents',
  3: 'Documents Received',
  4: 'Documents Unavailable',
  5: 'Expired',
  6: 'Reconciled',
  7: 'Invalid',
  8: 'Dismissed',
}

export const REFUND_STATUSES: { [key: number]: string } = {
  1: 'Eligible',
  2: 'Submitted',
  3: 'Approved',
  4: 'Found',
  5: 'Pending Eligibility',
  6: 'Reimbursed',
  7: 'Replaced',
  8: 'Declined',
  9: 'Already Reimbursed',
}
