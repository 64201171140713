import rdscApi from './../../reducers/request/rdsc'
import { HttpStatusCode } from '../../types'

const extension = rdscApi.injectEndpoints({
  endpoints: (build) => ({
    authorize: build.mutation<
      HttpStatusCode,
      { username: string; password: string }
    >({
      query: ({ username, password }) => ({
        url: 'authorize',
        method: 'POST',
        body: { username, password },
        responseHandler: (res) => res.text(),
        // (res) => (res ? ['data'] : []),
      }),
      invalidatesTags: (res) =>
        res ? ['Claims', 'Stats', 'Accounts', 'AccountMappings'] : [],
    }),
  }),
})

export const { useAuthorizeMutation } = extension
export default extension
