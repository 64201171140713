import { isEmpty, uniqBy } from 'lodash'
import React from 'react'
import { Check, Copy, X, XCircle } from 'react-feather'
import TextareaAutosize from 'react-textarea-autosize'
import { SpinnerCircularSplit } from 'spinners-react'

import useClipboard from './../lib/hooks/clipboard'
import { RDSCClaim } from '../../types'
import { useApplyChanges } from '../hooks/component/claims-inputs'

export default function InputsComponent({
  selectedCustomerId,
  selectedShipmentsData,
  selectedShipmentIds,
  unselectRows,
}: {
  selectedCustomerId: number
  selectedShipmentsData: RDSCClaim[]
  selectedShipmentIds: string[]
  unselectRows: () => void
}) {
  const [shipmentIdCopy, shipmentIdIsCopied] = useClipboard()
  const [caseIdCopy, caseIdIsCopied] = useClipboard()

  const {
    selectAction,
    selectedAction,
    caseId,
    // warnCaseId,
    // isDisplayingConfirmation,
    isDisplayingError,
    isDisplayingSuccess,
    request,
    details,
    setNotes,
    notes,
    applyChanges,
    setCaseId,
    // setIsDisplayingConfirmation,
  } = useApplyChanges({
    selectedCustomerId,
    selectedShipmentsData,
    selectedShipmentIds,
    unselectRows,
  })

  const existingNote =
    uniqBy(selectedShipmentsData, 'shipmentId').length === 1
      ? (
          selectedShipmentsData.find((it) => !isEmpty(it.caseManagerNotes)) ||
          {}
        ).caseManagerNotes || ''
      : ''

  const existingNotes =
    uniqBy(selectedShipmentsData, 'shipmentId').length > 1
      ? uniqBy(
          selectedShipmentsData.filter((it) => !isEmpty(it.caseManagerNotes)),
          'shipmentId'
        )
      : []

  const selectedCaseIds = uniqBy(
    selectedShipmentsData.filter((it) => !isEmpty(it.amzCaseId)),
    'amzCaseId'
  ).map((it) => it.amzCaseId)

  const selectedCaseIdsString = selectedCaseIds.length
    ? selectedCaseIds.join(', ')
    : ''

  const { isError, isLoading } = request

  return (
    <>
      <div className="me-3 me-lg-0 mt-4 mt-lg-0">
        <label htmlFor="submittedShipmentId" className="form-label">
          Shipment ID{selectedShipmentIds.length > 1 ? 's' : ''}:
        </label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={selectedShipmentIds.join(', ')}
            disabled={true}
            id="submittedShipmentId"
            aria-describedby="submittedShipmentId"
          />
          <span
            className="input-group-text"
            onClick={() =>
              selectedShipmentIds.length === 0
                ? shipmentIdCopy(selectedShipmentIds.join(', '))
                : null
            }
          >
            <Copy className={`${shipmentIdIsCopied ? 'text-success' : ''}`} />
            <span
              className={shipmentIdIsCopied ? 'ms-3 text-success' : 'd-none'}
            >
              Copied!
            </span>
          </span>
        </div>
      </div>

      <div className="me-3 me-lg-0 mt-4 mt-lg-3">
        <label htmlFor="submittedCaseId" className="form-label">
          Case ID{selectedCaseIds.length > 1 && caseId === undefined ? 's' : ''}
          :
        </label>

        {caseId !== undefined ? (
          <div className="d-inline" title="Reset case ID">
            <XCircle className="ms-2" onClick={() => setCaseId(undefined)} />
          </div>
        ) : null}
        <div className="input-group">
          <input
            type="text"
            value={caseId !== undefined ? caseId : selectedCaseIdsString}
            onChange={(e) => setCaseId(e.target.value)}
            id="submittedCaseId"
            aria-describedby="submittedCaseId"
            disabled={
              selectedShipmentIds.length === 0 || selectedShipmentIds.length > 1
            }
            className={`form-control ${
              caseId !== undefined && isDisplayingError ? 'is-invalid' : ''
            }`}
          />
          <span
            className="input-group-text"
            onClick={() =>
              caseId ? caseIdCopy(caseId || selectedCaseIdsString) : null
            }
          >
            <Copy className={`${caseIdIsCopied ? 'text-success' : ''}`} />
            <span className={caseIdIsCopied ? 'ms-3 text-success' : 'd-none'}>
              Copied!
            </span>
          </span>
          <div className="invalid-feedback">Failed to update case id</div>
        </div>
      </div>

      <div className="me-3 me-lg-0 mt-4 mt-lg-3">
        <label htmlFor="actionType" className="form-label">
          Set Status:
        </label>

        {selectedAction !== 0 ? (
          <div className="d-inline" title="Reset status">
            <XCircle className="ms-2" onClick={() => selectAction(0)} />
          </div>
        ) : null}

        <select
          value={selectedAction || 0}
          onChange={(e) => selectAction(e.target.value)}
          aria-label="Default select example"
          disabled={selectedShipmentIds.length === 0}
          className={`form-select ${
            selectedAction && isDisplayingError ? 'is-invalid' : ''
          }`}
        >
          <option value={0}>Select Status</option>
          <option value={'reconcile-2'}>Additional Documents Requested</option>
          <option value={'reconcile-5'}>Expired</option>
          <option value={'reconcile-6'}>Reconciled</option>
          <option value={'reconcile-7'}>Invalid</option>
          <option value={'reconcile-8'}>Dismissed</option>
        </select>
        <div className="invalid-feedback">Failed to update status</div>
      </div>

      <div className="me-3 me-lg-0 mt-4 mt-lg-3">
        <label htmlFor="new-note" className="form-label">
          Notes:
        </label>
        {notes !== undefined ? (
          <div className="d-inline" title="Reset notes">
            <XCircle className="ms-2" onClick={() => setNotes(undefined)} />
          </div>
        ) : null}
        <TextareaAutosize
          maxRows={9}
          // should specify with what was updated
          className={`form-control ${
            notes !== undefined && isDisplayingError ? 'is-invalid' : ''
          }`}
          id="new-note"
          aria-label="note"
          disabled={selectedShipmentIds.length === 0}
          value={notes !== undefined ? notes : existingNote}
          onChange={(e) => setNotes(e.target.value)}
        ></TextareaAutosize>
        <div className="invalid-feedback">Failed to update note, try again</div>

        <div className="mt-3">
          {notes !== undefined && existingNotes.length > 0 ? (
            <span className="text-danger">Overwriting listed notes</span>
          ) : null}
        </div>

        {existingNotes.map((it, i) => {
          return (
            <div
              key={i}
              className={`card mb-1 ${
                notes !== undefined && notes !== it.caseManagerNotes
                  ? 'border-warning'
                  : ''
              }`}
            >
              <div className="card-body">
                <span className="card-title text-secondary">
                  {it.shipmentId}
                </span>
                <p className="card-text">{it.caseManagerNotes}</p>
              </div>
            </div>
          )
        })}
      </div>

      <div className="mt-4 flex-fill">
        <div className="float-end d-flex flex-row align-items-center">
          {isDisplayingSuccess ? (
            <Check className="text-success me-2" size={30} />
          ) : null}
          {isDisplayingError ? (
            <X className="text-danger me-2" size={30} />
          ) : null}
          {isLoading ? (
            <SpinnerCircularSplit className="me-2" size={30} />
          ) : null}
          <button
            disabled={selectedShipmentIds.length === 0 || isEmpty(details)}
            className="btn btn-md btn-success"
            onClick={() => {
              void applyChanges()
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  )
}
