import React from 'react'
import { Search, X } from 'react-feather'

type props = {
  claimsPersons: string[]
  selectedPersons: string[]
  removePerson: (person: string) => void
  addPerson: (person: string) => void
  setSearchQuery: (query: string) => void
}

export default function OverviewFilters({
  claimsPersons,
  selectedPersons,
  removePerson,
  addPerson,
  setSearchQuery,
}: props) {
  return (
    <div className="d-flex flex-row bg-light">
      <div className="ms-auto p-2">
        <div className="float-end">
          <div className="d-flex flex-row">
            {selectedPersons.map((person) => {
              return (
                <div
                  className="me-3 btn btn-sm btn-secondary"
                  key={person}
                  onClick={() => {
                    removePerson(person)
                  }}
                >
                  {person} <X {...{ size: 15 }} />
                </div>
              )
            })}

            <div className="form-group">
              <div className="input-group input-group-sm">
                <input
                  className="form-control"
                  type="text"
                  defaultValue=""
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="input-group-text">
                  <Search />
                </div>
              </div>
            </div>

            <div className="btn-group ms-2" role="group">
              <div className="btn-group btn-group-sm">
                <button
                  className="btn btn-light btn-outline-dark border-custom dropdown-toggle px-3"
                  type="button"
                  id="actionType"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={claimsPersons.length === 0}
                >
                  Person
                </button>
                <ul className="dropdown-menu" aria-labelledby="statusType">
                  {claimsPersons.map((person: string) => {
                    if (!person) return null

                    return (
                      <li key={person} onClick={() => addPerson(person)}>
                        <a className="dropdown-item" href="#">
                          {person}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
