import React from 'react'

import { RDSCClaim } from './../../types'
import SkusLayout from './skus-layout'
// import SKUsResolvedInputs from './skus-submitted-inputs'
import useSKUsTable from '../hooks/component/skus-table'

export default function ResolvedSKUs({
  data,
}: // selectedCustomerId,
{
  data: RDSCClaim[]
  selectedCustomerId?: number
}) {
  const { table } = useSKUsTable({ data, tabId: 'resolved' })

  return (
    <SkusLayout
      {...{
        tableComponent: table,
        // inputsComponent: (
        //   <SKUsSubmittedInputs
        //     {...{
        //       selectedShipmentsData,
        //       selectedShipmentIds,
        //       selectedCustomerId,
        //       unselectRows,
        //     }}
        //   />
        // ),
      }}
    />
  )
}
